import {
  FilterNames,
  FilterRole,
  FilterStatus,
  FilterTeams,
  PreviewFilterStatus,
  PreviewFilterNames,
  PreviewFilterTeams,
} from "../components/agent/agentFilters";
import React, { useEffect, useState } from "react";
import { fetchAgents, setData } from "./agentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import AgentCreate from "../components/agent/agentCreate";
import AgentTeams from "../components/agent/agentTeams";
import AgentView from "../components/agent/agentView";
import { Avatar } from "@agney/react-avatar";
import Switch from "../../shared/switch/Switch";
import Table from "../../shared/tableScope";
import { useExport } from "../../../hooks/useExport";

const Agents = () => {
  const { exportToCSV, setDataset, setHeaders } = useExport({
    fileName: "agents",
  });
  const { search, state } = useLocation();
  // eslint-disable-next-line
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(search);
  const [user, setUser] = useState({});
  const [drawers, setDrawers] = useState({
    view: false,
    update: false,
    create: false,
  });

  const { loading, filters, dataSet, originalDataSet, totalElements } =
    useSelector((state) => state.agents);

  useEffect(() => {
    return () => {
      setDrawers({
        view: false,
        update: false,
        create: false,
      });
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchAgents({ page: Number(queryParams.get("page") || 1), isAgent: true })
    );
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (user && user.id) {
      const index = dataSet.findIndex((t) => t.id === user.id);
      if (index > -1) setUser(dataSet[index]);
    }

    setHeaders([
      "Name",
      "Teams",
      "Role",
      "Hire Date",
      "Status",
      "Termination Date",
    ]);
    setDataset(
      [...(originalDataSet || [])].map((a) => ({
        name: a.name,
        teams: [...(a.teams || [])].map((t) => t.name).join(", "),
        role: a.role || "",
        hireDate: a.hireDate || "",
        accountStatus: !!a.isEnabled ? "Active" : "Terminated",
        terminationDate: a.terminationDate || "",
      }))
    );
    // eslint-disable-next-line
  }, [dataSet]);

  useEffect(() => {
    if (state && state?.user && dataSet && dataSet.length > 0) {

      const u = dataSet.find((x) => Number(x.id) === Number(state?.user.id));

      if (u) {
        setUser(u);

        setTimeout(() => {
          openDrawer("view");
        }, 700);
      } else {
        toast.error("User not found");
      }
    }
    // eslint-disable-next-line
  }, [state, dataSet]);

  const openDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : false,
        };
      }
    );
    setDrawers(drawersModified);
  };

  const closeDrawer = (type) => {
    let drawersModified = { ...drawers };
    Object.keys(drawersModified).forEach(
      // eslint-disable-next-line
      (key) => {
        const value = drawersModified[key];
        drawersModified = {
          ...drawersModified,
          [key]: key === type ? !value : value,
        };
      }
    );
    setDrawers(drawersModified);
    setUser({});
  };

  return (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
        <div className="flex flex-grow items-center space-x-4">
          <span className="text-2xl font-medium text-[#33475B]">All Users</span>

          <span className="text-sm font-normal text-[#0091AE]">
            ({totalElements || 0} users)
          </span>

          <div className="ml-6">
            <AgentCreate btnTitle="User" />
          </div>
        </div>
        <div className="flex flex-grow justify-end space-x-2">
          <div className="">
            <button
              type="button"
              onClick={exportToCSV}
              className="flex items-center space-x-1 rounded-full border border-[#32A6AE] px-2 py-3 text-[#425B76]"
            >
              <span>Export</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className="flex-shrink-0"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                  stroke="url(#paint0_linear_15758_11091)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.66669 6.6665L8.00002 9.99984L11.3334 6.6665"
                  stroke="url(#paint0_linear_15758_11091)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 10V2"
                  stroke="url(#paint0_linear_15758_11091)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_15758_11091"
                    x1="1.15745"
                    y1="10"
                    x2="14.3098"
                    y2="11.3038"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#32A6AE" />
                    <stop offset="1" stopColor="#00BBA7" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="">
        <Table
          filters={{ ...filters }}
          rowHeight={63}
          rowKey="id"
          pagination={{ pageSize: 10 }}
          columns={[
            {
              name: "Name",
              key: "name",
              headerClassName: "text-center",
              sortable: true,
              filter: {
                previewTemplate: <PreviewFilterNames />,
                component: <FilterNames />,
              },
              render: (text, record) => {
                return (
                  <div className="inline-flex items-center space-x-2">
                    <div className="mx-auto flex h-[38px] w-[38px] items-center justify-center rounded-full bg-[#FFCB9E] p-[2px]">
                      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white p-[5px]">
                        <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                          <Avatar
                            text={text || ""}
                            alt={record.userName}
                            backgrounds={[
                              "#f28482",
                              "#84a59d",
                              "#b08968",
                              "#eb5e28",
                              "#b8c0ff",
                            ]}
                            src=""
                            shape="circle"
                            className="absolute object-cover text-xs !font-normal text-white"
                            textColor={"#fff"}
                            textProcessor={(text) =>
                              text
                                .match(/(^\S\S?|\b\S)?/g)
                                .join("")
                                .match(/(^\S|\S$)?/g)
                                .join("")
                                .toUpperCase()
                            }
                            // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="inline-flex text-sm text-[#33475B]">
                        {text || ""}
                      </div>
                      <div className="inline-flex text-xs text-[#7B93AF]">
                        {record.userName || ""}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Teams",
              headerClassName: "text-center",
              key: "teams[].id",
              filter: {
                previewTemplate: <PreviewFilterTeams />,
                component: <FilterTeams />,
              },
              render: (params, record) => {
                return (
                  <div className="flex justify-center">
                    <AgentTeams teamsList={record.teams} />
                  </div>
                );
              },
            },
            // {
            //   name: "Email",
            //   key: "userName",
            //   sortable: true,
            //   filter: {
            //     component: <Filter />,
            //   },
            //   headerClassName: "text-left",
            // },
            {
              name: "Role",
              key: "role",
              sortable: true,
              filter: {
                // overview: <OverviewFilterRole />,
                component: <FilterRole />,
              },
              headerClassName: "text-center",
              className: "text-center",
            },
            {
              name: "Status",
              key: "isEnabled",
              headerClassName: "text-center",
              // sortable: true,
              filter: {
                previewCustom: true,
                previewTemplate: <PreviewFilterStatus />,
                component: <FilterStatus />,
                handleClose: () => {
                  dispatch(setData([...originalDataSet]));
                },
              },
              render: (text, record) => {
                return (
                  <div className="flex justify-center">
                    {!record.terminationDate ? (
                      <span className="rounded-full bg-[#C0EAED] bg-opacity-30 px-6 py-2 text-[#383874]">
                        Active
                      </span>
                    ) : (
                      <span className="rounded-full bg-opacity-30 bg-gradient-to-r from-[rgba(242,84,125,0.3)] to-[rgba(255,121,89,0.3)] px-6 py-2 text-[#33475B]">
                        Terminated
                      </span>
                    )}
                  </div>
                );
              },
            },
            {
              name: "Actions",
              key: "id",
              render: (text, record) => {
                return (
                  <div className="flex justify-center divide-x">
                    <AgentView.AgentViewBtn
                      toggleDrawer={() => {
                        setUser(record);
                        openDrawer("view");
                      }}
                      className="px-2"
                    >
                      View/Edit Agent
                    </AgentView.AgentViewBtn>
                  </div>
                );
              },
            },
          ]}
          loading={loading}
          dataSet={dataSet}
          totalItems={totalElements}
        />
      </div>

      <AgentView.AgentViewBox
        agent={user}
        isOpen={drawers.view}
        handleClose={() => closeDrawer("view")}
      />
    </div>
  );
};

export default Agents;
