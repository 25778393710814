import Login from "../pages/authentication/login";

const routes = [
  {
    keyIndex: "1",
    path: "/",
    title: "login",
    roles: [],
    privileges : [],
    element: <Login />,
  },
];

export default routes;
