import React, { useEffect, useMemo, useRef, useState } from "react";
import { normalized, options } from "./barChartUtil";

import Export from "./Export";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Screenshot from "./Screenshot";
import annotations from "highcharts/modules/annotations";
import customEvents from "highcharts-custom-events";
import exporting from "highcharts/modules/exporting";
import fullScreen from "highcharts/modules/full-screen";
import noDataToDisplay from "highcharts/modules/no-data-to-display";
import offlineExporting from "highcharts/modules/offline-exporting";
import roundedCorners from "highcharts-rounded-corners-fixed-negative";
import { useFullscreen } from "../../../hooks/useFullscreen";

roundedCorners(Highcharts);
fullScreen(Highcharts);
exporting(Highcharts);
offlineExporting(Highcharts);
noDataToDisplay(Highcharts);
annotations(Highcharts);
customEvents(Highcharts);

const leftArrowActiveUrl = `${process.env.PUBLIC_URL}/assets/images/icons/left-arrow-active.svg`;
const leftArrowDeactiveUrl = `${process.env.PUBLIC_URL}/assets/images/icons/left-arrow-deactive.svg`;
const rightArrowActiveUrl = `${process.env.PUBLIC_URL}/assets/images/icons/right-arrow-active.svg`;
const rightArrowDeactiveUrl = `${process.env.PUBLIC_URL}/assets/images/icons/right-arrow-deactive.svg`;

let remaining1 = null,
  remaining2 = null,
  remainingBox1 = null,
  remainingBox2 = null,
  definition = null,
  leftArrow = null,
  rightArrow = null;

const BarChart = ({ teams = [], loading = false, changePeriod }) => {
  const screenShotRef = useRef(null);
  const chartRef = useRef(null);
  const chartTotalRef = useRef(null);
  const { fullscreenRef, enterFullscreen, fullscreenActive } = useFullscreen();

  const [keys, setKeys] = useState([]);
  const [disabled, setDisabled] = useState([]);
  const [mode, setMode] = useState("weekly");

  useEffect(() => {
    if (chartRef) {
      if (loading) {
        chartRef.current.chart.showLoading("Loading");
        chartTotalRef.current.chart.showLoading("Loading");
      } else {
        chartRef.current.chart.hideLoading();
        chartTotalRef.current.chart.hideLoading();
      }
    }
  }, [loading]);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const [...data] = normalized(teams);

      let tmpKeys = [];

      const indexASAT = data.findIndex((x) => x.name === "ASAT");
      if (indexASAT > -1) {
        tmpKeys.push({ name: "ASAT", label: "ASAT", color: "#AE83E2" });
        chartTotalRef.current.chart.addSeries(data[indexASAT]);
      }

      const indexRES = data.findIndex((x) => x.name === "RES");
      if (indexRES > -1) {
        tmpKeys.push({ name: "RES", label: "RES", color: "#49C2CB" });
        chartTotalRef.current.chart.addSeries(data[indexRES]);
      }

      const indexCSAT = data.findIndex((x) => x.name === "CSAT");
      if (indexCSAT > -1) {
        tmpKeys.push({ name: "CSAT", label: "CSAT", color: "#FF9538" });
        chartTotalRef.current.chart.addSeries(data[indexCSAT]);
      }

      const indexJibeSessions = data.findIndex((x) => x.name === "JibeSession");
      if (indexJibeSessions > -1) {
        tmpKeys.push({
          name: "JibeSession",
          label: "Jibe Sessions",
          color: "#E6DDF8",
        });
        chartTotalRef.current.chart.addSeries(data[indexJibeSessions]);
      }

      setKeys(tmpKeys);

      data
        .filter((element, index) => index % 2 === 1)
        .forEach((team) => {
          chartRef.current.chart.addSeries(team);
        });

      if (teams.some((x) => x.name.includes("All Team"))) {
        let plotLines = [];

        if (indexCSAT > -1)
          plotLines.push({
            color: "#FF9538",
            width: 1,
            from: 1,
            value: data[indexCSAT].data[0][1],
            dashStyle: "dash",
          });

        if (indexRES > -1)
          plotLines.push({
            color: "#49C2CB",
            width: 1,
            value: data[indexRES].data[0][1],
            dashStyle: "dash",
          });

        if (indexASAT > -1)
          plotLines.push({
            color: "#43F1DF",
            width: 1,
            value: data[indexASAT].data[0][1],
            dashStyle: "dash",
          });

        chartTotalRef.current.chart.update({
          chart: {
            ...options.chart,
            marginLeft: 55,
          },
          yAxis: [
            {
              ...options.yAxis[0],
              labels: {
                style: {
                  color: "#425B76",
                  fontWeight: 400,
                },
              },
              plotLines,
            },
            ...options.yAxis.filter((y) => y.name !== "A0"),
          ],
          xAxis: {
            ...options.xAxis,
            type: "category",
            min: 0,
            max: 0,
            plotBands: [
              {
                color: "#f2eefb",
                from: -0.5,
                to: 0.5,
              },
            ],
            useHTML: false,
          },
        });

        chartRef.current.chart.update({
          chart: {
            ...options.chart,
            marginLeft: 50,
            marginRight: 85,
          },
          yAxis: [
            {
              ...options.yAxis[0],
              labels: {
                enabled: false,
              },
              plotLines,
            },
            {
              ...options.yAxis[1],
              offset: 35,
            },
            // ...options.yAxis.filter((y) => y.name !== "A0"),
          ],
        });
      }
    }
  }, [teams, fullscreenActive]);

  const handleResize = () => {
    chartRef.current.chart.setSize();
    chartRef.current.chart.redraw();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleLegend = (name) => {
    const point = chartRef.current.chart.series.find((x) => x.name === name);
    if (point.visible) {
      setDisabled([...disabled, name]);
      point.setVisible(false);
    } else {
      setDisabled([...disabled].filter((x) => x !== name));
      point.setVisible(true);
    }

    const point2 = chartTotalRef.current.chart.series.find(
      (x) => x.name === name
    );
    if (point2.visible) point2.setVisible(false);
    else point2.setVisible(true);
  };

  const handleChangePeriod = (mode) => {
    setMode(mode);
    changePeriod(mode);
  };

  const chartTotal = useMemo(() => {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...options,
          exporting: {
            enabled: false,
          },
          tooltip: {
            ...options.tooltip,
            positioner: function (t1, t2, point) {
              return {
                x:
                  point.plotX + t1 > this.chart.plotWidth
                    ? point.plotX / 2
                    : point.plotX,
                y: this.chart.plotHeight - t2,
              };
            },
            //   enabled: false,
          },
          xAxis: {
            ...options.xAxis,
            max: 0,
          },
          responsive: {},
        }}
        ref={chartTotalRef}
      />
    );
    // eslint-disable-next-line
  }, [teams, fullscreenActive]);

  const chartChild = useMemo(() => {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...options,
          chart: {
            ...options.chart,
            events: {
              redraw: function () {
                const chart = this;

                const scaleToResize =
                  chart.containerWidth < 600
                    ? 28
                    : chart.containerWidth < 900
                    ? 36
                    : 44;
                const positionTopTextWrapper =
                  chart.containerWidth < 600
                    ? 188
                    : chart.containerWidth < 900
                    ? 194
                    : 200;
                const positionTopText =
                  chart.containerWidth < 600
                    ? 192
                    : chart.containerWidth < 900
                    ? 198
                    : 204;

                let { min, max, dataMax } = chart.xAxis[0].getExtremes();

                const moveLeft = () => {
                  let { min, max, dataMin } = chart.xAxis[0].getExtremes();
                  if (min - 1 >= dataMin) {
                    min -= 1;
                    max -= 1;
                  }
                  chart.xAxis[0].setExtremes(min, max);
                };

                const moveRight = () => {
                  let { min, max, dataMax } = chart.xAxis[0].getExtremes();
                  if (max + 1 <= dataMax) {
                    min += 1;
                    max += 1;
                  }

                  chart.xAxis[0].setExtremes(min, max);
                };

                // chart.update({
                //   ...options,
                //   series: [],
                // });

                if (leftArrow) {
                  leftArrow.destroy();
                  leftArrow = null;
                }

                if (rightArrow) {
                  rightArrow.destroy();
                  rightArrow = null;
                }

                if (definition) definition.remove();
                definition = chart.renderer.definition({
                  tagName: "linearGradient",
                  attributes: {
                    id: "gradient-red",
                  },
                  children: [
                    {
                      tagName: "stop",
                      attributes: {
                        offset: 0,
                      },
                    },
                    {
                      tagName: "stop",
                      attributes: {
                        offset: 1,
                      },
                    },
                  ],
                });

                if (remainingBox1) {
                  remainingBox1.destroy();
                  remainingBox1 = null;
                }
                if (remaining1) {
                  remaining1.destroy();
                  remaining1 = null;
                }

                if (min > 0) {
                  leftArrow = chart.renderer
                    .image(
                      leftArrowActiveUrl,
                      15,
                      150,
                      scaleToResize,
                      scaleToResize
                    )
                    .attr({ zIndex: 997, cursor: "pointer" });
                  leftArrow.on("click", moveLeft).add();

                  remainingBox1 = chart.renderer
                    .circle(30, positionTopTextWrapper, 12)
                    .attr({
                      fill: "url(#gradient-red)",
                      zIndex: 998,
                    })
                    .add();

                  remaining1 = chart.renderer
                    .text(min, min >= 10 ? 23 : 27, positionTopText)
                    .attr({ zIndex: 999 })
                    .css({
                      fontWeight: "normal",
                      fontSize: "12px",
                      color: "white",
                    })
                    .add();
                } else {
                  leftArrow = chart.renderer
                    .image(
                      leftArrowDeactiveUrl,
                      15,
                      150,
                      scaleToResize,
                      scaleToResize
                    )
                    .attr({ zIndex: 997, cursor: "pointer" })
                    .add();
                }

                if (remainingBox2) {
                  remainingBox2.destroy();
                  remainingBox2 = null;
                }
                if (remaining2) {
                  remaining2.destroy();
                  remaining2 = null;
                }

                if (dataMax - max > 0) {
                  rightArrow = chart.renderer
                    .image(
                      rightArrowActiveUrl,
                      chart.plotWidth + 55,
                      150,
                      scaleToResize,
                      scaleToResize
                    )
                    .attr({ zIndex: 997, cursor: "pointer" });
                  rightArrow.on("click", moveRight).add();

                  remainingBox2 = chart.renderer
                    .circle(chart.plotWidth + 60, positionTopTextWrapper, 12)
                    .attr({
                      fill: "url(#gradient-red)",
                      zIndex: 998,
                    })
                    .add();

                  remaining2 = chart.renderer
                    .text(
                      dataMax - max >= 0 ? dataMax - max : 0,
                      chart.plotWidth + (dataMax - max >= 10 ? 53 : 57),
                      positionTopText
                    )
                    .attr({ zIndex: 999 })
                    .css({
                      fontWeight: "normal",
                      fontSize: "12px",
                      color: "white",
                    })
                    .add();
                } else {
                  rightArrow = chart.renderer
                    .image(
                      rightArrowDeactiveUrl,
                      chart.plotWidth + 55,
                      150,
                      scaleToResize,
                      scaleToResize
                    )
                    .attr({ zIndex: 997, cursor: "pointer" })
                    .add();
                }

                const lines = document.querySelectorAll(
                  ".teams-chart .highcharts-plot-line"
                );

                [...lines].forEach((line) => {
                  const d = line.getAttribute("d");
                  line.setAttribute("d", d.replaceAll(/M (50) /g, `M 0 `));
                });
              },
            },
          },
        }}
        ref={chartRef}
      />
    );
    // eslint-disable-next-line
  }, [teams, fullscreenActive]);

  return (
    <div
      className="w-full bar-wrapper relative rounded-xl p-2 border border-cyan-400 bg-white flex flex-col justify-center"
      ref={fullscreenRef}
    >
      <div className="flex justify-between items-center space-x-2 p-2 w-full">
        {/* Legends */}
        <div className="inline-flex items-center space-x-3">
          {keys.map((key) => (
            <div
              key={key.name}
              className={`inline-flex items-center space-x-1 text-[#425B76] cursor-pointer ${
                [...disabled].includes(key.name) ? "line-through" : ""
              }`}
              onClick={() => handleToggleLegend(key.name)}
            >
              <span className={`w-3 h-3 bg-[${key.color}] rounded-full`} />
              <span className="">{key.label}</span>
            </div>
          ))}
        </div>

        {/* Actions */}
        <div className="inline-flex space-x-4 items-center">
          <div className="inline-flex space-x-2.5 items-center">
            <button
              type="button"
              onClick={() => {
                handleChangePeriod("weekly");
              }}
              className={`rounded-md ${
                mode === "weekly" ? "bg-orange-400" : "bg-cyan-300"
              } w-6 h-6 font-bold text-white text-lg leading-3 flex items-center justify-center`}
            >
              <span>W</span>
            </button>
            <button
              type="button"
              onClick={() => {
                handleChangePeriod("monthly");
              }}
              className={`rounded-md ${
                mode === "monthly" ? "bg-orange-400" : "bg-cyan-300"
              } w-6 h-6 font-bold text-white text-lg leading-3 flex items-center justify-center`}
            >
              <span>M</span>
            </button>
            <button
              type="button"
              onClick={() => {
                handleChangePeriod("yearly");
              }}
              className={`rounded-md ${
                mode === "yearly" ? "bg-orange-400" : "bg-cyan-300"
              } w-6 h-6 font-bold text-white text-lg leading-3 flex items-center justify-center`}
            >
              <span>Y</span>
            </button>
          </div>

          <div className="inline-flex space-x-4 items-center">
            {!fullscreenActive && (
              <button
                type="button"
                className="text-cyan-400"
                onClick={enterFullscreen}
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4 text-cyan-500 cursor-pointer"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                </svg>
              </button>
            )}

            <Screenshot screenShotRef={screenShotRef} />

            <Export dataset={teams} />
          </div>
        </div>
      </div>

      <div className="flex px-2 w-full" ref={screenShotRef}>
        {/* Total */}
        <div className="w-1/3 md:w-1/5 lg:w-1/6 min-w-[16rem]">
          {chartTotal}
        </div>

        {/* Teams */}
        <div className="flex-grow min-w-0 teams-chart">{chartChild}</div>
      </div>
    </div>
  );
};

export default BarChart;
