import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import servicesServices from "../../../services/services.services";

const initialState = {
  loading: false,
  error: undefined,
  originalDataSet: [],
  dataSet: [],
  users: [],
  totalElements: 0,
  filters: {},
};

export const fetchTeams = createAsyncThunk(
  "teams/fetchTeams",
  async (params) => {
    const teamRes = await servicesServices.fetchTeams(params);
    const userRes = await servicesServices.fetchUsers();

    return { team: teamRes.data, user: userRes.data.result };
  }
);

export const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    setData: (state, action) => {
      state = { ...state, dataSet: action.payload };

      return state;
    },
    teamUpdateOne: (state, action) => {
      // TODO: use url or base64 for avatar
      const { avatar, ...payload } = action.payload;

      const sourceIndex = [...(state.originalDataSet || [])].findIndex(
        (t) => t.id === payload.id
      );

      let originalDataSet = [...state.originalDataSet];
      if (sourceIndex > -1) {
        originalDataSet.splice(sourceIndex, 1, payload);
      }

      const index = [...(state.dataSet || [])].findIndex(
        (t) => t.id === payload.id
      );

      let dataSet = [...state.dataSet];
      if (index > -1) dataSet.splice(index, 1, payload);

      state = { ...state, originalDataSet, dataSet };

      return state;
    },
    teamDeleteOne: (state, action) => {
      state.dataSet = [...state.dataSet].filter((t) => t.id === action.payload);
      state.originalDataSet = [...state.originalDataSet].filter(
        (t) => t.id === action.payload
      );

      return state;
    },
    teamCreate: (state, action) => {
      state.originalDataSet = [action.payload, ...state.originalDataSet];
      state.dataSet = [action.payload, ...state.dataSet];

      return state;
    },
    teamFilter: (state, action) => {
      let filters = { ...state.filters };
      if (action.payload.action === "ADD") {
        filters = { ...filters, [action.payload.key]: action.payload.value };
      } else if (action.payload.action === "REMOVE") {
        delete filters[action.payload.key];
      }
      state = {
        ...state,
        filters,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeams.pending, (state) => {
      state = { ...state, loading: true, error: undefined, totalElements: 0 };
      return state;
    });
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      const dataSet = [...action.payload.team.result].map((team) => {
        let users = [...action.payload.user].filter((user) =>
          [...(user?.teamNames || [])].includes(team.name)
        );

        return {
          ...team,
          supervisor: [...(users || [])].find(
            (user) =>
              user.role.toLowerCase() === "supervisor" ||
              user.role.toLowerCase() === "manager"
          ),
          agents: [...(users || [])].filter(
            (user) => user.role.toLowerCase() === "agent"
          ),
          users,
        };
      });

      state = {
        ...state,
        loading: false,
        originalDataSet: dataSet,
        dataSet: dataSet,
        users: [...action.payload.user],
        totalElements:
          action.payload.team.totalRows ||
          action.payload.team.result.length ||
          0,
      };
      return state;
    });
    builder.addCase(fetchTeams.rejected, (state, action) => {
      state = { ...state, loading: false, error: action.payload };
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const { teamUpdateOne, teamDeleteOne, teamCreate, teamFilter, setData } =
  teamSlice.actions;

export default teamSlice.reducer;
