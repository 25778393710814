// https://demo.zacoustic.com/api/Jibe/jibe-progress-over-time-stats?fromDate=09/16/2023&toDate=09/23/2023

import request from "../helpers/request";

function fetchJibeProgressOverTimeStats(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(
      `/api/Jibe/jibe-progress-over-time-stats`,
      params
    );
}

const jibeServices = {
    fetchJibeProgressOverTimeStats,
};

export default jibeServices;
