import React, { Fragment } from "react";

import { useMatches } from "react-router-dom";

const Breadcrumbs = () => {
  let matches = useMatches();
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data));

  return (
    <div className="flex items-center gap-2 text-sm font-normal text-[#0091AE]">
      <div>
        <svg
          viewBox="0 0 20 20"
          fill="none"
          className="h-5 w-5 flex-shrink-0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 7.50008L10 1.66675L17.5 7.50008V16.6667C17.5 17.1088 17.3244 17.5327 17.0118 17.8453C16.6993 18.1578 16.2754 18.3334 15.8333 18.3334H4.16667C3.72464 18.3334 3.30072 18.1578 2.98816 17.8453C2.67559 17.5327 2.5 17.1088 2.5 16.6667V7.50008Z"
            stroke={`url(#active)`}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 18.3333V10H12.5V18.3333"
            stroke={`url(#active)`}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="active"
              x1="1.44681"
              y1="1.66675"
              x2="18.0341"
              y2="2.16006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2547D" />
              <stop offset="1" stopColor="#FF7959" />
            </linearGradient>
            <linearGradient
              id="default"
              x1="1.44681"
              y1="2.5"
              x2="18.0307"
              y2="3.04801"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32A6AE" />
              <stop offset="1" stopColor="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
          <div
            className={`${index + 1 === crumbs.length ? "text-[#33475B]" : ""}`}
          >
            {crumb}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
