import React, { useMemo } from "react";

import AgentSelect from "./AgentSelect";
import BarChart from "./BarChart";
import DateRangePicker from "./DateRangePicker";
import RFCSelect from "./RFCSelect";

const TeamComparisonChart = ({ teams = [], loading = false, changePeriod }) => {
  const chart = useMemo(
    () => (
      <BarChart
        teams={[...teams]}
        loading={loading}
        changePeriod={changePeriod}
      />
    ),
    // eslint-disable-next-line
    [loading, teams]
  );

  return (
    <div className="flex flex-col justify-start space-y-4 !border-0">
      <div className="inline-flex flex-col md:flex-row flex-wrap md:items-center">
        <div className="">
          <AgentSelect />
        </div>
        <div className="">
          <RFCSelect />
        </div>
        <div className="">
          <DateRangePicker />
        </div>
      </div>

      {chart}
    </div>
  );
};

export default TeamComparisonChart;
