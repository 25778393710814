import request from "../helpers/request";

function fetchCoachingSessions(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/coaching/coaching-summary-stats?fromDate=04/10/2023&toDate=04/20/2023`, params);
}

function fetchCoachedAgents(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/coaching/coached-agents?fromDate=04/10/2023&toDate=04/20/2023`, params);
}

const coachingServices = {
  fetchCoachingSessions,
  fetchCoachedAgents,
};

export default coachingServices;
