import React, { memo, useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import variablePie from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

variablePie(Highcharts);

const options = {
  chart: {
    type: "packedbubble",
    render: "chart",
    height: "480px",
    // width: "480px",
    style: {
      fontFamily: "'Roboto'",
    },
    events: {},
  },
  title: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false, // hide button
  },
  tooltip: {
    crosshairs: true,
    followPointer: false,
    formatter: function () {
      if (this.point.formatPrefix !== "parentNode")
        return `<div sty><span style='color:${
          this.point.options.tooltipColor
        };font-size:1rem;display:block;padding-bottom:4px'>${
          this.point.options.name
        }</span><span style="color:#7B93AF;font-size=0.5rem">${
          this.point.options.valueChanged > 0 ? "+" : "-"
        }${this.point.options.value}%</span></div>`;
      else {
        return false;
      }
    },
    borderWidth: 0,
    borderRadius: 16,
    backgroundColor: "rgba(255, 255, 255, 1)",
    useHTML: true,
  },
  plotOptions: {
    packedbubble: {
      zMin: 0,
      zMax: 100,
      minSize: "10%",
      maxSize: "120%",
      layoutAlgorithm: {
        bubblePadding: 40,
        gravitationalConstant: 0.001,
        seriesInteraction: false,
        dragBetweenSeries: true,
        parentNodeLimit: true,
        enableSimulation: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function () {
          const { width } = this.point.marker;

          const fontSize =
            width > 100
              ? "24px"
              : width > 80
              ? "20px"
              : width > 40
              ? "16px"
              : width / 75 + "rem";
          const subFontSize =
            width > 100
              ? "16px"
              : width > 80
              ? "14px"
              : width > 60
              ? "12px"
              : width / 120 + "rem";
          const sign = this.point.valueChanged > 0 ? "+" : "";

          return `
            <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-weight:400;">
              <span style="font-size:${fontSize};min-width:${
            width - 18
          }px;text-align:center">${sign}${this.point.valueChanged}</span>
              <div style="width:100%;height:${
                width / 120
              }rem;font-size:${subFontSize};white-space:nowrap;text-overflow:ellipsis;overflow:hidden;${
            width < 100 && "font-weight:300;"
          }">
                <div style="position:absolute;left:0;right:0;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;text-align:center">${
                  this.point.name
                }</div>
              <div>
            </div>`;
        },
        useHTML: true,
        allowOverlap: true,
        style: {
          color: "white",
          textOutline: "none",
          fontWeight: "normal",
          textOverflow: "allow",
          whiteSpace: "normal",
          overflow: "hidden",
        },
      },
    },
    series: {
      marker: {
        fillOpacity: 1,
        // lineWidth: 0,
        // lineColor: null,
        states: {
          hover: {
            fillOpacity: 1,
          },
          select: {
            fillOpacity: 1,
          },
        },
      },
      states: {
        hover: {
          fillOpacity: 1,
        },
        select: {
          fillOpacity: 1,
        },
      },
    },
  },
  series: [{ name: "data", data: [] }],
  // responsive: {
  //   rules: [
  //     {
  //       condition: {
  //         minWidth: 900,
  //       },
  //       chartOptions: {
  //         chart: {
  //           height: "420px",
  //           width: "100%",
  //         },
  //       },
  //     },
  //   ],
  // },
};

const optionsOn = {
  ...options,
  chart: {
    type: "packedbubble",
    render: "chart2",
    height: "480px",
    // width: "480px",
    style: {
      fontFamily: "'Roboto'",
    },
    events: {},
  },
  plotOptions: {
    ...options.plotOptions,
    packedbubble: {
      ...options.plotOptions.packedbubble,
      zMin: 0,
      zMax: 100,
      minSize: "20%",
      maxSize: "150%",
      layoutAlgorithm: {
        bubblePadding: 5,
        gravitationalConstant: 0.001,
        seriesInteraction: false,
        dragBetweenSeries: true,
        parentNodeLimit: true,
        enableSimulation: false,
        splitSeries: true,
        initialPositions: "circle",
      },
    },
    series: {
      ...options.plotOptions.series,
      marker: {
        fillOpacity: 1,
        lineWidth: 0,
        lineColor: null,
        states: {
          hover: {
            fillOpacity: 1,
          },
          select: {
            fillOpacity: 1,
          },
        },
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
};

const BubbleChart = ({
  items = [],
  title = "",
  chanelStatus = "on",
  selected = null,
  loading = false,
}) => {
  const chartRef = useRef(null);
  const chart2Ref = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current && items && items.length > 0) {
      // chartRef.current.chart.setTitle({ text: title });

      const min = items.hasMin("valueChanged");
      const max = items.hasMax("valueChanged");

      chartRef.current.chart.series[0].update({
        name: title,
        color: chanelStatus === "on" ? "#b690dc" : "#fff",
        data: [...items].map((d) => ({
          ...d,
          value: Math.abs(d.valueChanged),
          tooltipColor: d.valueChanged > 0 ? "#32A6AE" : "#F2547D",
          color: {
            linearGradient: {
              x1: 0,
              x2: 0.75,
              y1: 0,
              y2: 0.75,
            },
            stops: [
              [0, d.valueChanged > 0 ? "#32A6AE" : "#F2547D"],
              [1, d.valueChanged > 0 ? "#00BBA7" : "#FF7959"],
            ],
          },
        })),
      });

      chartRef.current.chart.update({
        plotOptions: {
          packedbubble: {
            zMin: min?.valueChanged || 0 < 0 ? 0 : min?.valueChanged || 0,
            zMax: max?.valueChanged || 100,
          },
        },
      });

      chartRef.current.chart.redraw();
      // chartRef.current.chart.render();
    }

    if (chart2Ref && chart2Ref.current && items && items.length > 0) {
      // chart2Ref.current.chart.setTitle({ text: title });

      const min = items.hasMin("valueChanged");

      chart2Ref.current.chart.series[0].update({
        name: title,
        color: chanelStatus === "on" ? "#b690dc" : "#fff",
        data: [...items].map((d) => ({
          ...d,
          value: Math.abs(d.valueChanged),
          tooltipColor: d.valueChanged > 0 ? "#32A6AE" : "#F2547D",
          color: {
            linearGradient: {
              x1: 0,
              x2: 0.75,
              y1: 0,
              y2: 0.75,
            },
            stops: [
              [0, d.valueChanged > 0 ? "#32A6AE" : "#F2547D"],
              [1, d.valueChanged > 0 ? "#00BBA7" : "#FF7959"],
            ],
          },
        })),
      });

      chart2Ref.current.chart.update({
        plotOptions: {
          packedbubble: {
            zMin: min?.valueChanged || 0 < 0 ? 0 : min?.valueChanged || 0,
            zMax: 60,
          },
        },
      });

      chart2Ref.current.chart.redraw();
      // chart2Ref.current.chart.render();
    }

    window.dispatchEvent(new Event("resize"));
  }, [items, title, chanelStatus]);

  useEffect(() => {
    if (loading) {
      chart2Ref.current.chart.showLoading("Loading...");
      chartRef.current.chart.showLoading("Loading...");
    } else {
      chart2Ref.current.chart.hideLoading();
      chartRef.current.chart.hideLoading();
    }

    return () => {
      // chart2Ref.current.chart.hideLoading();
      // chartRef.current.chart.hideLoading();
    };
  }, [loading]);

  useEffect(() => {
    if (chanelStatus === "on")
      chart2Ref.current.chart.series.forEach((serie) => {
        serie.points.forEach((point) => {
          if (point.id == selected) {
            point.setState("hover");
            chart2Ref.current.chart.tooltip.refresh(point);
          }
        });
      });
    else
      chartRef.current.chart.series.forEach((serie) => {
        serie.points.forEach((point) => {
          if (point.id == selected) {
            point.setState("hover");
            chartRef.current.chart.tooltip.refresh(point);
          }
        });
      });
  }, [selected]);

  return (
    <>
      <div
        id="chart"
        className={`w-full justify-center ${
          chanelStatus === "off" ? "flex" : "hidden"
        }`}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
          containerProps={{ style: { width: "100%" } }}
        />
      </div>
      <div
        id="chart2"
        className={`w-full justify-center ${
          chanelStatus === "on" ? "flex" : "hidden"
        }`}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={optionsOn}
          ref={chart2Ref}
          containerProps={{ style: { width: "100%" } }}
        />
      </div>
    </>
  );
};

export default BubbleChart;
