import React, { useEffect, useRef } from "react"

import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import Switch from "../../shared/switch/Switch"
import TagSelect from "../../shared/tagSelect/TagSelect"

const options = {
  events: {
    load: function () {
      const chart = this
      const series = chart.series[0]
      const points = series.data

      points.forEach(function (point) {
        if (point.y <= 1) {
          const myOffset = 40
          const { x: centerX, y: centerY } = point.graphic.attr()
          const { x, y } = point.dataLabel.attr()
          const angle = point.angle
          point.dataLabel.attr({
            x: x + Math.cos(angle) * myOffset,
            y: y + Math.sin(angle) * myOffset
          })
          point.dataLabel.css({ color: "black" })
        }
      })
    }
  },
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie"
  },
  title: {
    text: ""
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>"
  },

  credits: {
    enabled: false
  },

  plotOptions: {
    pie: {
      allowPointSelect: false,
      borderWidth: 3,
      dataLabels: {
        // shadow: false,
        enabled: true,
        useHTML: true,
        color: "#ffffff",
        align: "center",
        style: {
          fontSize: "90%",
          color: "#ffffff",
          borderColor: "transparent",
          borderWidth: 0,
          fontWeight: "bold"
        },
        verticalAlign: "middle",
        distance: -40,
        format: "<span>{point.percentage:.0f}%</span>"
      },
      align: "center",
      verticalAlign: "middle",
      connectorColor: "silver",
      showInLegend: true
    }
  },
  series: [{ name: "Survey name", colors: ["#5bc7d1", "#b58fe4", "#fe9f4b"] }]
}

const Responses = ({ data = [] }) => {
  const chartASATRef = useRef(null)
  const chartRESRef = useRef(null)
  const chartCSATRef = useRef(null)

  useEffect(() => {
    chartASATRef.current.chart.series[0].setData(data["ASAT"])
    chartRESRef.current.chart.series[0].setData(data["RES"])
    chartCSATRef.current.chart.series[0].setData(data["CSAT"])
  }, [data])

  return (
    <div className="px-2">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h1 className="text-xl font-medium text-[#425B76]">Survay name</h1>
          <span className="text-xs text-[#7B93AF]">
            live in 17/jun/2022 and 31/jun/2022
          </span>
        </div>
        <div className="flex space-x-4">
          <Switch
            defaultValue={"off"}
            options={[
              { label: "On", value: "on" },
              { label: "Jibe Agent Off", value: "off" }
            ]}
          />

          <Switch
            defaultValue={"agent"}
            options={[
              { label: "Customer", value: "customer" },
              { label: "Agent", value: "agent" }
            ]}
          />
        </div>
      </div>
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartASATRef}
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRESRef}
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartCSATRef}
        />
      </div>

      <div className="mt-8 flex items-center justify-between space-x-2">
        <div className="flex space-x-2">
          <TagSelect value="all" id="all" title="ALL" />
          <TagSelect
            value="all3"
            id="all3"
            title="ASAT"
            defaultChecked={true}
          />
          <TagSelect value="all3" id="all3" title="RES" defaultChecked={true} />
          <TagSelect
            value="all3"
            id="all3"
            title="CSAT"
            defaultChecked={true}
          />
          <TagSelect value="all3" id="all3" title="NPS" />
          <TagSelect value="all3" id="all3" title="CES" />
        </div>

        <div className="">
          <a
            href="/"
            className="flex items-center justify-center rounded-full bg-[#E6F7F8] px-8 py-3"
          >
            <span className="text-[#7B93AF]">Show survay Report</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Responses
