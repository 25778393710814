import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import Screenshot from "../../jibe-dashboard/team-comparison-chart/Screenshot";
import Export from "../../jibe-dashboard/team-comparison-chart/Export";
import { useFullscreen } from "../../../hooks/useFullscreen";

exporting(Highcharts);

const options = {
  chart: {
    borderWidth: 0,
    plotBorderColor: "#98DDE1",
    plotBorderWidth: 1,
    // margin: [50, 100, 50, 100],
  },
  title: { text: "" },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
    align: "left",
    reversed: true,
    x: 80,
    verticalAlign: "top",
    y: -10,
    floating: true,
    backgroundColor:
      Highcharts.defaultOptions.legend.backgroundColor || // theme
      "rgba(255,255,255,0.25)",
  },
  plotOptions: {
    series: {
      borderRadius: "10%",
      borderWidth: 2,
      borderColor: "#E4D6F5",
      // pointStart: Date.UTC(2010, 0, 1),
      pointInterval: 24 * 3600 * 1000, // one day
    },
    spline: {
      lineWidth: 3,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
      marker: {
        enabled: false,
      },
    },
  },
  xAxis: {
    lineColor: "#98DDE1",
    lineWidth: 1,
    tickWidth: 0,
    tickmarkPlacement: "between",
    // 98DDE1
    type: "datetime",
    labels: {
      step: 1,
      style: {
        color: "#32A6AE",
      },
    },
  },
  time: {
    useUTC: false,
  },
  yAxis: [
    {
      min: 0,
      // max: 10,
      gridLineWidth: 0,
      labels: {
        step: 1,
        format: "{value} min",
        style: {
          color: "#425B76",
        },
      },
      title: {
        text: "",
        style: {
          color: "#425B76",
        },
      },
      opposite: true,
    },
    {
      // Secondary yAxis
      min: 0,
      max: 100,
      gridLineWidth: 0,
      title: {
        text: "",
        style: {
          color: "##425B76",
        },
      },
      labels: {
        step: 1,
        format: "{value} %",
        style: {
          color: "##425B76",
        },
      },
      opposite: false,
    },
  ],
  series: [],
  // ],
};

const ComplexChart = ({ loading, items = [], categories = [] }) => {
  const [disabled, setDisabled] = useState([]);

  const screenShotRef = useRef(null);
  const chartRef = useRef();
  const { fullscreenRef, enterFullscreen, fullscreenActive } = useFullscreen();

  const handleToggleLegend = (name) => {
    const point = chartRef.current.chart.series.find((x) => x.name === name);
    if (point.visible) {
      setDisabled([...disabled, name]);
      point.setVisible(false);
    } else {
      setDisabled([...disabled].filter((x) => x !== name));
      point.setVisible(true);
    }
  };

  useEffect(() => {
    if (loading) {
      chartRef.current.chart.showLoading("Loading...");
    }
    if (!loading) {
      chartRef.current.chart.hideLoading();

      while (chartRef.current.chart.series.length > 0)
        chartRef.current.chart.series[0].remove(true);

      if (items.length > 0) {
        const chart = chartRef.current.chart;
        let series = [
          {
            key: "ahtInSeconds",
            name: "AHT",
            color: "#E4D6F5",
            type: "column",
            yAxis: 0,
            data: [],
            tooltip: {
              valueSuffix: " min",
            },
          },
          ...(categories || []).map((category) => ({
            key: category.name,
            name: category.title,
            color: category.color,
            type: "spline",
            yAxis: 1,
            data: [],
            legendSymbol: "lineMarker",
            marker: {
              enabled: false,
            },
            tooltip: {
              valueSuffix: " mb",
            },
          })),
        ];

        series.forEach((serie) => {
          chart.addSeries({
            ...serie,
            data: [
              ...items.map((item) => ({
                x: dayjs(item.subToDate, "MMMM DD").valueOf(),
                y: item[serie.key],
              })),
            ],
          });
        });

        chart.redraw();
      }
    }
  }, [loading]);

  return (
    <div
      className="bar-wrapper relative flex w-full flex-col justify-center rounded-xl bg-white p-2"
      ref={fullscreenRef}
    >
      <div className="flex w-full items-center justify-between space-x-2 p-2 py-6">
        <div className="flex w-full items-center justify-between space-x-2 p-2">
          {/* Legends */}
          <div className="inline-flex items-center space-x-3">
            {categories && categories.length > 0 ? (
              <>
                {[
                  ...categories,
                  { title: "AHT", name: "AHT", color: "#E4D6F5", legendSymbol: "circle" },
                ].map((key) => (
                  <div
                    key={key.title}
                    className={`inline-flex cursor-pointer items-center space-x-2 text-[#425B76] ${
                      [...disabled].includes(key.title) ? "line-through" : ""
                    }`}
                    onClick={() => handleToggleLegend(key.title)}
                  >
                    {key.legendSymbol !== "circle" ? (
                      <span
                        className={`h-1 w-5 bg-[${key.color}] rounded-full`}
                        style={{ background: key.color }}
                      ></span>
                    ) : (
                      <span
                        className={`h-4 w-4 bg-[${key.color}] rounded-full`}
                        style={{ background: key.color }}
                      />
                    )}
                    <span className="">{key.title}</span>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>

          {/* Actions */}
          <div className="inline-flex items-center space-x-4">
            <div className="inline-flex items-center space-x-4">
              {!fullscreenActive && (
                <button
                  type="button"
                  className="text-cyan-400"
                  onClick={enterFullscreen}
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-4 w-4 cursor-pointer text-cyan-500"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                  </svg>
                </button>
              )}

              <Screenshot screenShotRef={screenShotRef} />

              <Export dataset={[]} />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-2" ref={screenShotRef}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </div>
  );
};

export default ComplexChart;
