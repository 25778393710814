import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../../shared/form/input";
import { teamFilter } from "../../teams/teamSlice";

export const PreviewFilterNames = ({ data }) => {
  const { originalDataSet } = useSelector((state) => state.teams);

  return (
    <>
      {[...originalDataSet]
        .filter((r) =>
          data.some((x) => x.toLowerCase() === r.name.toLowerCase())
        )
        .map((team) => (
          <div className="flex items-center gap-2">
            <span className="">{team.name}</span>
            <span className="text-[#0091AE]">
              ({(team.users || []).length} Agents)
            </span>
          </div>
        ))}
    </>
  );
};

export const FilterNames = ({ setPage, columnKey, resetSort }) => {
  const { originalDataSet } = useSelector((state) => state.teams);
  const [text, setText] = useState("");
  const [selectedObjects, setSelectedObjects] = useState([]);
  const dispatch = useDispatch();

  const handleClear = () => {
    setSelectedObjects([]);
    resetSort();
  };

  const handleSelected = (name) => {
    const index = selectedObjects.findIndex((s) => s === name);
    if (index > -1) {
      let tmp = [...selectedObjects];
      tmp.splice(index, 1);
      setSelectedObjects(tmp);
    } else {
      setSelectedObjects([...selectedObjects, name]);
    }
  };

  useEffect(() => {
    if (selectedObjects && selectedObjects.length > 0) {
      dispatch(
        teamFilter({ key: columnKey, value: selectedObjects, action: "ADD" })
      );
      setPage(1);
    } else {
      dispatch(
        teamFilter({ key: columnKey, value: selectedObjects, action: "REMOVE" })
      );
    }
  }, [selectedObjects]);

  return (
    <Fragment>
      <div
        className="flex cursor-pointer select-none items-center justify-between py-2"
        onClick={handleClear}
      >
        <div className="flex items-center justify-between gap-3">
          <div className="flex h-8 w-8 items-center justify-center rounded-md bg-[#98DDE1] text-white">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.875 3.75H3.125H13.125"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75H11.875Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.75 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.25 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span>Clear filters</span>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="flex flex-col gap-3">
          <div className="inline-flex">
            <Input onChange={(e) => setText(e.target.value)} />
          </div>
          <div className="scrollbar-rounded-full flex max-h-[300px] min-h-[300px] flex-col overflow-hidden overflow-y-scroll pr-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-700 scrollbar-w-2">
            {[...originalDataSet]
              .filter((r) => r.name.toLowerCase().includes(text.toLowerCase()))
              .map((team) => (
                <TeamItem
                  key={team.id}
                  team={team}
                  selected={
                    selectedObjects.findIndex((s) => s === team.name) > -1
                  }
                  handleClick={handleSelected}
                />
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const PreviewFilterStatus = ({ data }) => {
  return (
    <div className="inline-flex items-center gap-1">
      <span className="text-base font-semibold">Team Status:</span>
      <span className="capitalize">{!!data ? "Active" : "Terminated"}</span>
    </div>
  );
};

export const FilterStatus = ({ setPage, columnKey }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (status) {
      dispatch(
        teamFilter({ key: columnKey, value: status === "true", action: "ADD" })
      );
      setPage(1);
    } else {
      dispatch(
        teamFilter({
          key: columnKey,
          value: status === "true",
          action: "REMOVE",
        })
      );
    }
  }, [status]);

  const handleClear = () => {
    setStatus(null);
  };

  return (
    <div className=" divide-y divide-[#C0EAED] ">
      <div
        className="flex cursor-pointer select-none items-center justify-between py-2"
        onClick={handleClear}
      >
        <div className="flex items-center justify-between gap-3">
          <div className="flex h-8 w-8 items-center justify-center rounded-md bg-[#98DDE1] text-white">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.875 3.75H3.125H13.125"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75H11.875Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.75 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.25 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span>Clear filters</span>
        </div>
      </div>
      <div className="flex flex-col gap-1.5 divide-y divide-[#C0EAED]">
        <div
          className="flex cursor-pointer items-center justify-between pb-2 pt-3"
          onClick={() => setStatus("true")}
        >
          <span className={`text-sm ${status === "true" ? "font-bold" : ""}`}>
            Show Active
          </span>
          {status === "true" ? (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="10"
                fill="white"
                stroke="#98DDE1"
              />
              <circle cx="10.5" cy="10.5" r="6.5" fill="#FF7700" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="10"
                fill="white"
                stroke="#98DDE1"
              />
            </svg>
          )}
        </div>
        <div
          className="flex cursor-pointer items-center justify-between pb-2 pt-3"
          onClick={() => setStatus("false")}
        >
          <span className={`text-sm ${status === "false" ? "font-bold" : ""}`}>
            Show Terminated
          </span>
          {status === "false" ? (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="10"
                fill="white"
                stroke="#98DDE1"
              />
              <circle cx="10.5" cy="10.5" r="6.5" fill="#FF7700" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="10"
                fill="white"
                stroke="#98DDE1"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export const PreviewFilterSupervisor = ({ data }) => {
  const { users } = useSelector((state) => state.teams);

  return (
    <>
      {[...users]
        .filter((u) => data.some((x) => x === u.id))
        .map((user) => (
          <div className="flex items-center gap-2">
            <span className="">{user.name}</span>
            <span className="text-[#0091AE]">
              ({(user.teamNames || []).length} Teams)
            </span>
          </div>
        ))}
    </>
  );
};

export const FilterSupervisor = ({ setPage, columnKey }) => {
  const { users } = useSelector((state) => state.teams);
  const [text, setText] = useState("");
  const [selectedObjects, setSelectedObjects] = useState([]);
  const dispatch = useDispatch();

  const handleClear = () => {
    setSelectedObjects([]);
  };

  const handleSelected = (id) => {
    const index = selectedObjects.findIndex((s) => s === id);
    if (index > -1) {
      let tmp = [...selectedObjects];
      tmp.splice(index, 1);
      setSelectedObjects(tmp);
    } else {
      setSelectedObjects([...selectedObjects, id]);
    }
  };

  useEffect(() => {
    if (selectedObjects && selectedObjects.length > 0) {
      dispatch(
        teamFilter({ key: columnKey, value: selectedObjects, action: "ADD" })
      );
      setPage(1);
    } else {
      dispatch(
        teamFilter({ key: columnKey, value: selectedObjects, action: "REMOVE" })
      );
    }
  }, [selectedObjects]);

  return (
    <Fragment>
      <div
        className="flex cursor-pointer select-none items-center justify-between py-2"
        onClick={handleClear}
      >
        <div className="flex items-center justify-between gap-3">
          <div className="flex h-8 w-8 items-center justify-center rounded-md bg-[#98DDE1] text-white">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.875 3.75H3.125H13.125"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75H11.875Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.75 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.25 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span>Clear filters</span>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="flex flex-col gap-3">
          <div className="inline-flex">
            <Input onChange={(e) => setText(e.target.value)} />
          </div>
          <div className="scrollbar-rounded-full flex max-h-[300px] min-h-[300px] flex-col overflow-hidden overflow-y-scroll pr-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-700 scrollbar-w-2">
            {[...users]
              .filter(
                (r) =>
                  r.name.toLowerCase().includes(text.toLowerCase()) &&
                  r.role.toLowerCase() !== "agent"
              )
              .map((user) => (
                <UserItem
                  key={user.id}
                  user={user}
                  selected={
                    selectedObjects.findIndex((s) => s === user?.id) > -1
                  }
                  handleClick={handleSelected}
                />
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const FilterAgents = ({ setPage, columnKey }) => {
  const { users, originalDataSet } = useSelector((state) => state.teams);
  const [text, setText] = useState("");
  const [selectedObjects, setSelectedObjects] = useState([]);
  const dispatch = useDispatch();

  const handleClear = () => {
    setSelectedObjects([]);
  };

  const handleSelected = (id) => {
    const index = selectedObjects.findIndex((s) => s === id);
    if (index > -1) {
      let tmp = [...selectedObjects];
      tmp.splice(index, 1);
      setSelectedObjects(tmp);
    } else {
      setSelectedObjects([...selectedObjects, id]);
    }
  };

  useEffect(() => {
    if (selectedObjects && selectedObjects.length > 0) {
      dispatch(
        teamFilter({ key: columnKey, value: selectedObjects, action: "ADD" })
      );
      setPage(1);
    } else {
      dispatch(
        teamFilter({ key: columnKey, value: selectedObjects, action: "REMOVE" })
      );
    }
  }, [selectedObjects]);

  return (
    <Fragment>
      <div
        className="flex cursor-pointer select-none items-center justify-between py-2"
        onClick={handleClear}
      >
        <div className="flex items-center justify-between gap-3">
          <div className="flex h-8 w-8 items-center justify-center rounded-md bg-[#98DDE1] text-white">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.875 3.75H3.125H13.125"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75H11.875Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.75 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.25 6.875V10.625"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span>Clear filters</span>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="flex flex-col gap-3">
          <div className="inline-flex">
            <Input onChange={(e) => setText(e.target.value)} />
          </div>
          <div className="scrollbar-rounded-full flex max-h-[300px] min-h-[300px] flex-col overflow-hidden overflow-y-scroll pr-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-700 scrollbar-w-2">
            {[
              {
                id: null,
                name: "Empty Member",
                teamNames: originalDataSet.filter((t) => t.users.length === 0),
              },
              ...users,
            ]
              .filter((r) => r.name.toLowerCase().includes(text.toLowerCase()))
              .map((user) => (
                <UserItem
                  key={user.id}
                  user={user}
                  selected={
                    selectedObjects.findIndex((s) => s === user?.id) > -1
                  }
                  handleClick={handleSelected}
                />
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const UserItem = ({ user, selected = false, handleClick }) => {
  return (
    <div
      className={`flex cursor-pointer items-center justify-between gap-3 border-[#C0EAED] p-2 ${
        selected
          ? "my-1 rounded-lg border bg-white shadow"
          : "border-b last:border-0 "
      }`}
      onClick={() => handleClick(user.id)}
    >
      <div className="flex items-center gap-2">
        <div
          className={`flex h-8 w-8 items-center justify-center rounded-lg text-center text-lg font-bold text-white ${
            selected ? "bg-[#935AD8]" : "bg-[#98DDE1]"
          }`}
        >
          <span>
            {user.name
              .match(/(^\S\S?|\b\S)?/g)
              .join("")
              .match(/(^\S|\S$)?/g)
              .join("")
              .toUpperCase()}
          </span>
        </div>
        <div className="text-sm">
          {user.name}{" "}
          <span className="text-[#0091AE]">
            ({(user.teamNames || []).length} Teams)
          </span>
        </div>
      </div>
      <div className="">
        {selected && (
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13.5" cy="13.5" r="13.5" fill="#FF7700" />
            <path
              d="M7 14.5L10.5 18L19.5 9"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

const TeamItem = ({ team, selected = false, handleClick }) => {
  return (
    <div
      className={`flex cursor-pointer items-center justify-between gap-3 border-[#C0EAED] p-2 ${
        selected
          ? "my-1 rounded-lg border bg-white shadow"
          : "border-b last:border-0 "
      }`}
      onClick={() => handleClick(team.name)}
    >
      <div key={team.id} className="flex items-center gap-2">
        <div
          className={`flex h-8 w-8 items-center justify-center rounded-lg text-center text-lg font-bold text-white ${
            selected ? "bg-[#935AD8]" : "bg-[#98DDE1]"
          }`}
        >
          {team.name
            .match(/(^\S\S?|\b\S)?/g)
            .join("")
            .match(/(^\S|\S$)?/g)
            .join("")
            .toUpperCase()}
        </div>
        <div className="text-sm">
          {team.name}{" "}
          <span className="text-[#0091AE]">
            ({(team.users || []).length} Agents)
          </span>
        </div>
      </div>
      <div className="">
        {selected && (
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13.5" cy="13.5" r="13.5" fill="#FF7700" />
            <path
              d="M7 14.5L10.5 18L19.5 9"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
