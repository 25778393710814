import "./styles.css";

import React, { useEffect, useState } from "react";

import AgentPerformance from "./AgentPerformance";
import Switch from "../../shared/switch/Switch";
import ChartCard from "./ChartCard";
import ComplexChart from "./ComplexChart";
import TrendsChart from "../contact-reason-performance/TrendsChart";
import Skeleton from "react-loading-skeleton";
import servicesServices from "../../../services/services.services";
import { isEmpty } from "lodash";

const colors = ["#F70", "#935AD8", "#49C2CB", "#F2547D", "#b8c0ff"];

const OverallPerformance = ({ range, rangeObj }) => {
  const [drawer, setDrawer] = useState(false);
  const [title, setTitle] = useState("Phone");
  const [categories, setCategories] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [resonsData, setResonsData] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [surveyTypeId, setSurveyTypeId] = useState("2");

  const openDrawer = () => {
    setDrawer(true);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const fetchData = async () => {
    try {
      const [{ data: data1 }, { data: data2 }] = await Promise.all([
        servicesServices.fetchOverallPerformance({
          fromDate: `${rangeObj.from.month}/${rangeObj.from.day}/${rangeObj.from.year}`,
          toDate: `${rangeObj.to.month}/${rangeObj.to.day}/${rangeObj.to.year}`,
          surveyTypeId,
        }),
        servicesServices.fetchAHTOverallPerformance({
          fromDate: `${rangeObj.from.month}/${rangeObj.from.day}/${rangeObj.from.year}`,
          toDate: `${rangeObj.to.month}/${rangeObj.to.day}/${rangeObj.to.year}`,
          surveyTypeId,
        }),
      ]);

      setDataSet([
        ...data1.result
          .concat(data2.result)
          .reduce(
            (m, o) =>
              m.set(o.subToDate, Object.assign(m.get(o.subToDate) || {}, o)),
            new Map()
          )
          .values(),
      ]);
    } catch (error) {
    } finally {
      setLoading2(false);
    }
  };

  const fetchSurveyQuestions = async () => {
    setLoading1(true);
    try {
      const { data } = await servicesServices.fetchSurveyQuestions({});
      setCategories(
        data.result.map((c, index) => ({
          name: c.internalName,
          title: c.shortName,
          id: c.shortName,
          color: colors[index],
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(true);
      setLoading1(false);
    }
  };

  // fetchOverallPerformance
  const handleChange = (v) => {
    setSurveyTypeId(v);
  };

  useEffect(() => {
    if (!loading1 && categories.length > 0) {
      if (!isEmpty(rangeObj.to) && !isEmpty(rangeObj.from)) fetchData();
    }
  }, [loading1]);

  useEffect(() => {
    fetchSurveyQuestions();
  }, []);

  useEffect(() => {
    fetchSurveyQuestions();
  }, [range, surveyTypeId]);

  return (
    <>
      <div className="box-wrapper">
        <div className="box-header">
          <div className="inline-flex items-center gap-4">
            <div className="inline-flex items-center gap-2">
              <h1 className="box-title">Overall Performance</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M8.2 5.4H6.8V4H8.2M8.2 11H6.8V6.8H8.2M7.5 0.5C6.58075 0.5 5.67049 0.68106 4.82122 1.03284C3.97194 1.38463 3.20026 1.90024 2.55025 2.55025C1.2375 3.86301 0.5 5.64348 0.5 7.5C0.5 9.35651 1.2375 11.137 2.55025 12.4497C3.20026 13.0998 3.97194 13.6154 4.82122 13.9672C5.67049 14.3189 6.58075 14.5 7.5 14.5C9.35651 14.5 11.137 13.7625 12.4497 12.4497C13.7625 11.137 14.5 9.35651 14.5 7.5C14.5 6.58075 14.3189 5.67049 13.9672 4.82122C13.6154 3.97194 13.0998 3.20026 12.4497 2.55025C11.7997 1.90024 11.0281 1.38463 10.1788 1.03284C9.3295 0.68106 8.41925 0.5 7.5 0.5Z"
                  fill="#7B93AF"
                />
              </svg>
            </div>
            <Switch
              // defaultValue={"2"}
              options={[
                { label: "Jibe Data", value: "2" },
                { label: "Customer", value: "4" },
              ]}
              onChange={handleChange}
              value={surveyTypeId}
            />
          </div>
          <AgentPerformance.Button toggleDrawer={openDrawer} />
        </div>

        <div className="grid w-full grid-cols-2 items-center gap-6 border-t border-[#C0EAED] py-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-8">
          {loading1 || loading2 ? (
            <>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
            </>
          ) : (
            <>
              {categories
                .sort((a, b) => a.title - b.title)
                .map((category, index) => (
                  <ChartCard
                    key={`per-${index}`}
                    chartName={category.title}
                    data={
                      dataSet[dataSet.length - 1]
                        ? dataSet[dataSet.length - 1][category.name] || 0
                        : 0
                    }
                    // data={0}
                    color={category.color}
                    range={range}
                    className="flex items-center"
                  />
                ))}

              <div className={`flex w-full items-center gap-4 rounded-lg p-6`}>
                <div className="inline-flex flex-col items-center justify-center whitespace-nowrap">
                  <span>AHT</span>
                  <div className="flex items-center gap-2 text-sm">
                    <span style={{ padding: "0px" }}>
                      {/* {upDownPercentAmount > 0 ? (
              <svg
                height={"1rem"}
                width={"1rem"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 25 25"
                strokeWidth="4"
                stroke="#11b4aa"
                style={{ padding: "0px" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                />
              </svg>
            ) : (
              upDownPercentAmount < 0 && ( */}
                      <svg
                        height={"1rem"}
                        width={"1rem"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 25 25"
                        strokeWidth="4"
                        stroke="#fe765c"
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
                        />
                      </svg>
                      {/* )
            )} */}
                    </span>
                    <span
                      style={{
                        color: "#98abc2",
                        margin: "0 0 0 2px",
                      }}
                    >
                      {/* {upDownPercentAmount}% */}
                      2%
                    </span>
                    <span
                      style={{
                        backgroundColor: "#f2f6f9",
                        borderRadius: "5px",
                        color: "#98abc2",
                        padding: "2px 5px",
                        margin: "0px",
                      }}
                    >
                      {range <= 1 ? "24 h" : `${range} d`}
                    </span>
                  </div>
                </div>
                <div className="text-secandary inline-flex w-full max-w-[100px] flex-col items-center justify-start">
                  <div className="inline-flex flex-col items-start justify-start">
                    <div className="flex items-end">
                      <span className="text-center text-3xl">
                        {dataSet[dataSet.length - 1]
                          ? Math.floor(
                              (dataSet[dataSet.length - 1].ahtInSeconds || 0) /
                                60
                            )
                          : 0}
                      </span>
                      <span className="mb-1">min</span>
                    </div>
                    <div className="flex items-end">
                      <span className="text-center text-3xl">
                        {dataSet[dataSet.length - 1]
                          ? (dataSet[dataSet.length - 1].ahtInSeconds || 0) % 60
                          : 0}
                      </span>
                      <span className="mb-0.5">s</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="w-full">
          <ComplexChart
            items={[...(dataSet || [])]}
            loading={loading2 || loading1}
            categories={[...(categories || [])]}
          />
        </div>

        <div className="w-full">
          <TrendsChart
            items={[...(resonsData || [])]}
            loading={loading2 || loading1}
            fetcher={() => {}}
            dateRange={range <= 1 ? "24 h" : `${range} d`}
            title={title}
            setTitle={setTitle}
          />
        </div>
      </div>

      <AgentPerformance.Box
        isOpen={drawer}
        handleClose={closeDrawer}
        range={rangeObj}
      />
    </>
  );
};

export default OverallPerformance;
