import "react-modern-drawer/dist/index.css";

import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import BtnPrimary from "../../../shared/buttons/Primary";
import DatePicker from "../../../shared/form/datepicker";
import Drawer from "react-modern-drawer";
import Input from "../../../shared/form/input";
import { ROLE } from "../../../../constants/enums/user.contsnts";
import { agentsCreate } from "../../agents/agentSlice";
import userManagementServices from "../../../../services/userManagement.services";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar } from "@agney/react-avatar";

const AgentCreate = ({ btnTitle = "Agent" }) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const { teams: teamsList } = useSelector((state) => state.agents);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        avatar: yup.mixed().required(),
        teamNames: yup
          .array()
          .of(
            yup.object({
              id: yup.string().required(),
            })
          )
          .min(1),
      })
    ),
    defaultValues: {
      TeamIds: [],
    },
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const { data } = await userManagementServices.createUser({
        TeamIds: values.teamNames.map((t) => Number(t.id)),
        Name: values.name,
        RoleId: values.role.value,
        Email: values.email,
        PhoneNumber: values.phoneNumber,
        HireDate: values.hireDate
          ? `${values.hireDate.day}/${values.hireDate.month}/${values.hireDate.year}`
          : null,
        // TerminationDate: values.terminationDate
        //   ? `${values.terminationDate.day}/${values.terminationDate.month}/${values.terminationDate.year}`
        //   : null,
        // IsTerminated: !values.terminationDate ? 0 : 1,
      });

      dispatch(
        agentsCreate({
          ...values,
          id: data.result,
          role: values.role.label,
          userName: values.email,
        })
      );

      toggleDrawer();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [selectedTeams] = watch(["teamNames"]);

  const removeTeam = (team) => {
    setValue(
      "teamNames",
      [...(selectedTeams || [])].filter((s) => s.name !== team)
    );
  };

  return (
    <>
      <BtnPrimary onClick={toggleDrawer}>
        <span className="relative z-10">Add {btnTitle}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </BtnPrimary>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="max-h-screen overflow-hidden overflow-y-auto rounded-l-lg !bg-[#FDF9FB] pb-5"
        size={380}
        enableOverlay={true}
        lockBackgroundScroll={true}
        overlayOpacity={0.1}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="min-h-[101px] w-full bg-gradient-to-r from-[rgba(0,0,0,0)] via-[#FF7700] to-[rgba(0,0,0,0)]">
            <div
              className="relative flex min-h-[100px] w-full flex-col items-center justify-center bg-white"
              style={{
                backgroundImage:
                  "url(/assets/images/patterns/drawer-header-pattern.png)",
              }}
            >
              <div className="absolute left-0 top-0 flex w-full justify-between px-5 py-5">
                <div className="">
                  <button type="button" onClick={toggleDrawer}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 12H5"
                        stroke="#32A6AE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 19L5 12L12 5"
                        stroke="#32A6AE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col space-y-3">
                  <button type="submit">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="#32A6AE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div
                className={`absolute top-[2rem] mx-auto my-0 flex h-[132px] w-[132px] items-center justify-center rounded-full bg-white bg-gradient-to-tr p-0.5 ${
                  errors.avatar
                    ? "from-red-500 to-red-700"
                    : "to-[rgba(255,119,0, 0.7)] from-[#FF7700]"
                }`}
              >
                <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white p-2">
                  <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                    <img src="" className="absolute object-cover " alt="" />
                  </div>

                  <div className="absolute bottom-0 right-0 h-10 w-10 rounded-full bg-white p-1">
                    <input
                      type="file"
                      {...register("avatar")}
                      id="avatar"
                      className="hidden"
                    />
                    <label
                      htmlFor="avatar"
                      className="flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[linear-gradient(91.89deg,#32A6AE_-6.8%,#00BBA7_100.34%)]"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 1.62132C11.8978 1.2235 12.4374 1 13 1C13.5626 1 14.1022 1.2235 14.5 1.62132C14.8978 2.01915 15.1213 2.55871 15.1213 3.12132C15.1213 3.68393 14.8978 4.2235 14.5 4.62132L5 14.1213L1 15.1213L2 11.1213L11.5 1.62132Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </label>
                  </div>

                  <span>{errors?.avatar?.message}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[68px] space-y-6 px-5">
            <div className="">
              <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                Name
              </label>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    hint={errors?.name?.message}
                    invalid={errors?.name}
                  />
                )}
              />
            </div>

            <div className="">
              <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                Role
              </label>
              <Controller
                control={control}
                name="role"
                render={({ field: { value, ...slug } }) => {
                  return (
                    <Select
                      {...slug}
                      options={Object.entries(ROLE).map(([key, value]) => ({
                        label: (key + "").toPascalCase(),
                        value,
                      }))}
                      unstyled
                      isSearchable={false}
                      isClearable={false}
                      placeholder={"Select Role"}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="drawer-small-select-container"
                      classNamePrefix="drawer-small-select"
                    />
                  );
                }}
              />
            </div>

            <div className="">
              <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                Team
              </label>
              <Controller
                control={control}
                name="teamNames"
                render={({ field: { onChange, ...slug } }) => (
                  <Select
                    {...slug}
                    isMulti
                    // value={[...teamsList].filter((t) =>
                    //   [...(slug.value || [])].includes(t.name)
                    // )}
                    options={[...teamsList].filter(
                      (t) => ![...(slug.value || [])].some((v) => v.id === t.id)
                    )}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o}
                    unstyled
                    controlShouldRenderValue={false}
                    isClearable={false}
                    placeholder={"Search team..."}
                    isSearchable
                    className="drawer-small-select-container"
                    classNamePrefix="drawer-small-select"
                    onChange={(options) => {
                      setValue("teamNames", [...options.map((o) => o)]);
                    }}
                    components={{
                      ...components,
                      IndicatorSeparator: () => null,
                      // DropdownIndicator: DropdownIndicator,
                      // Option: CustomOption
                    }}
                  />
                )}
              />

              <div
                className={`flex flex-wrap gap-2 px-2 ${
                  [...(selectedTeams || [])].length > 0 ? "py-3" : ""
                } rounded-b-lg border border-t-0 border-[#e5e7eb] bg-[#F8EEF3]`}
              >
                {[...(selectedTeams || [])].map((team, index) => (
                  <div
                    key={team.name}
                    className="flex items-center space-x-2 rounded-full border border-[#98DDE1] bg-white px-3 py-1 pr-1"
                  >
                    <div className="flex flex-grow flex-col space-y-1 truncate">
                      <span className="truncate text-[#33475B]">
                        {team.name}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => removeTeam(team.name)}
                      className="flex-shrink-0 cursor-pointer"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#98DDE1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 9L9 15"
                          stroke="#98DDE1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9 9L15 15"
                          stroke="#98DDE1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="">
              <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                Email
              </label>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input
                    {...field}
                    hint={errors?.email?.message}
                    invalid={errors?.email}
                  />
                )}
              />
            </div>

            <div className="">
              <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                Phone Number
              </label>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <Input
                    {...field}
                    hint={errors?.phoneNumber?.message}
                    invalid={errors?.phoneNumber}
                  />
                )}
              />
            </div>

            <div className="flex items-start space-x-8">
              <div className="inline-flex w-full flex-shrink-0 basis-[calc(50%-1rem)] flex-col">
                <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                  Hire Date
                </label>
                <Controller
                  control={control}
                  name="hireDate"
                  render={({ field }) => <DatePicker {...field} />}
                />
              </div>

              {/* <div className="inline-flex w-full flex-col">
                <label className={`mb-1 block font-medium text-[#7B93AF]`}>
                  Terminated Date
                </label>
                <Controller
                  control={control}
                  name="terminationDate"
                  render={({ field }) => <DatePicker {...field} />}
                />
              </div> */}
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default AgentCreate;

export const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center space-x-4 py-3">
        <div className="h-14 w-14 flex-shrink-0">
          <div className="h-full w-full overflow-hidden rounded-full border border-[#98DDE1] bg-white">
            <Avatar
              text={props.value.name || ""}
              alt={props.value.userName}
              backgrounds={[
                "#f28482",
                "#84a59d",
                "#b08968",
                "#eb5e28",
                "#b8c0ff",
              ]}
              src=""
              shape="circle"
              className="absolute object-cover text-base !font-normal text-white"
              textColor={"#fff"}
              textProcessor={(text) =>
                text
                  .match(/(^\S\S?|\b\S)?/g)
                  .join("")
                  .match(/(^\S|\S$)?/g)
                  .join("")
                  .toUpperCase()
              }
              // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
            />
          </div>
        </div>
        <div className="flex flex-grow flex-col space-y-1 truncate">
          <span className="truncate text-[#33475B]">{props.value.name}</span>
          <span className="truncate text-xs text-[#7B93AF]">
            {props.value.email}
          </span>
        </div>
        <div className="flex-shrink-0 cursor-pointer">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_15416_66024)">
              <path
                d="M25.4853 16.9999H8.51472"
                stroke="#32A6AE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 8.51465V25.4852"
                stroke="#32A6AE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_15416_66024">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(17 0.0292969) rotate(45)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </components.Option>
  );
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 17.4167C15.4183 17.4167 19 14.1334 19 10.0833C19 6.03324 15.4183 2.75 11 2.75C6.58172 2.75 3 6.03324 3 10.0833C3 14.1334 6.58172 17.4167 11 17.4167Z"
          stroke="#CACED8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 19.2502L16.65 15.2627"
          stroke="#CACED8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};
