import React, { useEffect, useRef, useState } from "react";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";

const options = {
  chart: {
    height: 100,
    type: "spline",
    space: [2, 2, 2, 2],
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: "",
  },
  time: {
    useUTC: false,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    outside:true,
    useHTML: true,
    valueSuffix: "",
    padding: 0,
    style: {
      padding: 0,
    },
    shadow: false,
    borderRadius: 8,
    className: 'p-0 m-0',
    formatter: function () {
      return `
      <div class='flex flex-col rounded-lg overflow-hidden' style='box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;min-width: 120px;min-heigth: 120px'>
        <div class='flex justify-between gap-2 items-center px-4 py-2'>
          <span style='color:#32A6AE'>${this.series.name}</span>
          <span class='text-lg'>${this.point.y}%</span>
        </div>
        <div class='flex justify-between gap-2 items-center px-4 rounded-b-lg py-1' style='background-color:${this.series.color};color:#ffffff;'>
          <span class='text-xs'>${dayjs(this.point.x).format("DD MMM YYYY")}</span>
        </div>
      </div>`
    },
    headerFormat: "",
    pointFormat: ""
  },
  xAxis: {
    // minPadding: 0.1,
    // maxPadding: 0.1,
    tickWidth: 0,
    type: "datetime",
    lineWidth: 0,
    labels: {
      enabled: false,
    },
    title: {
      text: "",
    },
  },
  yAxis: {
    maxPadding: 0,
    minPadding: 0,
    min: 0,
    max: Math.max([0, 100]),
    tickInterval: 1,
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 0,
    startOnTick: false,
  },
  plotOptions: {
    series: {
      lineWidth: 4,
      // enableMouseTracking: false,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      marker: {
        enabledThreshold: 0,
        enabled: false,
      },
      name: "",
      data: [],
      pointStart: Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        0,
        0,
        0
      ),
      // pointInterval: , // one hour
      pointInterval: 36e5,
      relativeXValue: true,
      colorKey: "colorValue",
      zoneAxis: "x",
      zones: [
        {
          value: Date.UTC(
            new Date().getUTCFullYear(),
            new Date().getUTCMonth(),
            new Date().getUTCDate(),
            5,
            0,
            0
          ),
        },
      ],
    },
  ],
};

export default function SplineChart(props) {
  const { data, color, name, colorGradient } = props;
  const chartRef = useRef(null);
  const [colorGraph, setColorGraph] = useState("#fe9f4b");
  const [zoneColor, setZoneColor] = useState({});
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    if (colorGradient && colorGradient.stops.length > 0) {
      setZoneColor(colorGradient);
    }
    if (color && color.length > 0) {
      setColorGraph(color);
    }
    if (data && data.length > 0) {
      // const replacement = {
      //   y: data[data.length - 1],
      //   marker: {
      //     enabled: true,
      //   },
      // };
      // console.log("replacement",replacement)

      // data[data.length - 1] = replacement;
      setApiData(data.map((x) => x.y));
    }
  }, [props]);

  useEffect(() => {
    if (apiData.length > 0) {
      chartRef.current.chart.update({
        ...options,
        yAxis: {
          ...options.yAxis,
          max: Math.max(...apiData) + Math.max(...apiData) / 10,
          min: Math.min(...apiData) - Math.max(...apiData) / 10,
        },
      });

      chartRef.current.chart.series[0].update({
        marker: {
          enabledThreshold: 0,
          enabled: false,
        },
        name,
        data: [
          ...apiData.map(item => (
            {
              y: item,
              marker: {
                enabled: true,
                symbol: "circle",
                height: 20,
                width: 20,
              },
            })),
        ],
        pointStart: data[0].x,
        // pointInterval: , // one hour
        pointInterval: 24 * 60 * 60 * 1000,
        // relativeXValue: true,
        colorKey: "colorValue",
        zoneAxis: "x",
        // zones: [
        //   {
        //     value: dayjs(data[0].x).add(1, "day").valueOf(),
        //     color: zoneColor,
        //   },
        // ],
        color: colorGraph,
      });
    }
  }, [apiData]);

  return (
    <div className="overflow-visiable">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}
