import React, { useEffect, useState } from "react";

import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import Dialog from "rc-dialog";
import dayjs from "dayjs";

const DateRangePicker = ({
  value = {
    from: null,
    to: null,
  },
  onChange,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    setSelectedDayRange(value);
  }, [value]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClearDates = () => {
    setSelectedDayRange({
      from: null,
      to: null,
    });
  };

  const selectLastWeek = (numberWeek = 1) => {
    const lastWeek = dayjs().subtract(numberWeek, "week");
    const today = dayjs();

    setSelectedDayRange({
      from: {
        year: lastWeek.year(),
        month: lastWeek.month() + 1,
        day: lastWeek.date(),
      },
      to: {
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      },
    });
  };

  const selectLastMonth = (numberMonth = 1) => {
    const today = dayjs();
    const lastMonth = dayjs().subtract(numberMonth, "month");

    setSelectedDayRange({
      from: {
        year: lastMonth.year(),
        month: lastMonth.month() + 1,
        day: lastMonth.date(),
      },
      to: {
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      },
    });
  };

  

  return (
    <>
      <div className="flex items-center border border-fourth-200 rounded-full text-primary">
        {selectedDayRange.from || selectedDayRange.to ? (
          <>
            <div
              className={`inline-flex flex-wrap items-center rounded-full px-4 pb-2`}
            >
              {selectedDayRange.from && (
                <div className="mr-3 mt-2 flex items-center space-x-2 truncate last:mr-0">
                  <span>{`${selectedDayRange.from.month}/${selectedDayRange.from.day}/${selectedDayRange.from.year}`}</span>
                </div>
              )}
              <div className="mr-2 mt-2 flex items-center space-x-2 truncate last:mr-0">
                <span className="font-semibold">TO</span>
              </div>
              {selectedDayRange.to && (
                <>
                  <div className="mr-3 mt-2 flex items-center space-x-2 truncate last:mr-0">
                    <span>{`${selectedDayRange.to.month}/${selectedDayRange.to.day}/${selectedDayRange.from.year}`}</span>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        <button
          type="button"
          onClick={showModal}
          className="relative inline-block overflow-hidden truncate rounded-full pr-5 py-2.5 text-sm font-semibold"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15.8333 3.33337H4.16667C3.24619 3.33337 2.5 4.07957 2.5 5.00004V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V5.00004C17.5 4.07957 16.7538 3.33337 15.8333 3.33337Z" stroke="#8D4ED9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.5 8.33337H17.5" stroke="#8D4ED9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.3333 1.66663V4.99996" stroke="#8D4ED9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.66669 1.66663V4.99996" stroke="#8D4ED9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>

      <Dialog
        visible={isModalOpen}
        className="!max-w-2xl"
        onClose={handleCancel}
        closeIcon={() => <></>}
      >
        <div className="flex">
          <div
            className={`relative mx-8 z-0 ${selectedDayRange.to && selectedDayRange.from && "multi-selected"
              }`}
          >
            <Calendar
              value={selectedDayRange}
              setSelectedDayRange={setSelectedDayRange}
              onChange={setSelectedDayRange}
              colorPrimary="#00BBA7"
              colorPrimaryLight="#C0EAED"
              // className="shadow-0"
              calendarClassName="custom-calendar"
              renderFooter={() => {
                return (
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex flex-col space-y-1 text-[#33475B]">
                      <table>
                        <tr>
                          <td className="text-right">
                            <span className="font-[400] text-cyan-400">
                              From:
                            </span>
                          </td>
                          <td className="!pl-2">
                            <span className="font-medium">
                              {selectedDayRange.from &&
                                dayjs(
                                  `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`
                                ).format("DD MMMM YYYY")}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">
                            <span className="font-[400] text-cyan-400">
                              To:
                            </span>
                          </td>
                          <td className="!pl-2">
                            <span className="font-medium">
                              {selectedDayRange.to &&
                                dayjs(
                                  `${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
                                ).format("DD MMMM YYYY")}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="">
                      <button
                        type="button"
                        onClick={() => {
                          onChange(selectedDayRange);
                          handleCancel();
                        }}
                        disabled={!selectedDayRange.from}
                        className={`inline-block items-center ${selectedDayRange.from
                            ? "bg-orange-400 text-white"
                            : "bg-[#F2EAFA] text-[#33475B] opacity-80"
                          } rounded-full !px-4 !py-2 text-sm `}
                      >
                        <span>Done</span>
                      </button>
                    </div>
                  </div>
                );
              }}
            />

            <button
              type="button"
              onClick={handleClearDates}
              className="absolute right-0 top-1.5 z-10 text-sm text-cyan-500 hover:text-cyan-500"
            >
              Clear
            </button>
          </div>

          <div className="flex flex-grow flex-col items-center justify-center space-y-3 px-4">
            <button
              type="button"
              onClick={() => selectLastWeek(1)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last week
            </button>

            <button
              type="button"
              onClick={() => selectLastWeek(2)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last two weeks
            </button>

            <button
              type="button"
              onClick={() => selectLastMonth(1)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last month
            </button>

            <button
              type="button"
              onClick={() => selectLastMonth(3)}
              className="w-full max-w-[150px] rounded-full bg-[#F2EAFA] px-5 py-2 text-sm text-[#425B76]"
            >
              Last 3 months
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DateRangePicker;
