import React, { useEffect, useMemo, useRef, useState } from "react";
import Segmented from "rc-segmented";
import BubbleChart from "./BubbleChart";
import Table from "./Table";
import { findAncestor } from "../../../helpers/util";
import servicesServices from "../../../services/services.services";
import "./styles.css";
import { NavLink } from "react-router-dom";

const category = ["Phone", "Email", "Social"];

const TrendsChart = ({ dateRange, title, setTitle }) => {
  const tableRef = useRef(null);
  const [chanelStatus, setChanelStatus] = useState("Channel");
  const [type, setType] = useState("ASAT");
  const [current, setCurrent] = useState(0);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState([]);

  const handleOnClick = (e) => {
    const parent = findAncestor(e.target, "tr");
    try {
      if (selected === parent.dataset.id) setSelected(null);
      else setSelected(parent.dataset.id);
    } catch (error) {}
  };

  const handlePreviousStep = () => {
    const index = category.findIndex((x) => x === title);
    setTitle(category[index - 1]);
    setCurrent(index - 1);
  };

  const handleNextStep = () => {
    const index = category.findIndex((x) => x === title);
    setTitle(category[index + 1]);
    setCurrent(index + 1);
  };

  const fetcher = async () => {
    try {
      setLoading(true);
      const { data } = await servicesServices.fetchReasons({
        filter: type || "ASAT",
        channel: "All Channels",
      });
      setDataset(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetcher();
  }, [type, title]);

  useEffect(() => {
    if (tableRef && tableRef.current)
      tableRef.current.addEventListener("click", handleOnClick);

    return () => {
      if (tableRef && tableRef.current)
        tableRef.current.removeEventListener("click", handleOnClick);
    };
  }, []);

  const table = useMemo(
    () => (
      <Table
        data={dataset}
        columns={[
          { title: "Channel | Volume", key: "name" },
          { title: "QA%", key: "qa" },
          { title: "Coaching %", key: "coaching" },
          { title: "Current AVG", key: "currentValue" },
        ]}
        loading={loading}
        ref={tableRef}
        selected={selected}
        dateRange={dateRange}
      />
    ),
    [dataset, selected, loading]
  );

  const chart = useMemo(
    () => (
      <BubbleChart
        items={[...(loading ? [] : dataset)]}
        dateRange={dateRange}
        title={title}
        loading={loading}
        selected={selected}
      />
    ),
    [dataset, selected, loading]
  );

  return (
    <div className="">
      <div className="mb-4 flex w-full flex-col items-start space-y-4 border-b border-teal-300 pb-4 lg:flex-row lg:items-center lg:space-y-0">
        <div className="trends-title w-full flex-grow">
          <span className="">Performance Trends</span>
        </div>

        <div className="flex space-x-2">
          <div className="">
            <Segmented
              className="label-semibold"
              defaultValue={chanelStatus}
              onChange={setChanelStatus}
              options={[
                {
                  label: "Channel",
                  value: "Channel",
                },
                {
                  label: "Team",
                  value: "Team",
                },
                {
                  label: "Agent",
                  value: "Agent",
                },
                {
                  label: "RFC",
                  value: "RFC",
                },
              ]}
            />
          </div>

          <div className="">
            <Segmented
              defaultValue={type}
              onChange={setType}
              options={[
                { label: "ASAT", value: "ASAT" },
                { label: "RES", value: "RES" },
                { label: "CSAT", value: "CSAT" },
              ]}
            />
          </div>
        </div>

        <div className="flex w-full flex-grow items-center justify-end space-x-2">
          <NavLink
            to="/dashboard/overview"
            className="btn btn-secondary btn-pill !gap-6 !p-3 !px-5"
          >
            <span className="cursor-pointer">More Analysis</span>
          </NavLink>
        </div>
      </div>
      <div className="flex">
        <div className="w-[520px] max-w-[520px] flex-shrink-0 border-r border-teal-300 pr-1 lg:w-[720px] lg:max-w-full">
          {table}
        </div>
        <div className="reletive flex flex-grow flex-col items-center justify-center gap-4">
          <div className="chart-wrapper">{chart}</div>
          <div className="flex space-x-2">
            <div className="flex space-x-1">
              <i className="status-color status-color__increase"></i>
              <span>Increase</span>
            </div>
            <div className="flex space-x-1">
              <i className="status-color status-color__decrease"></i>
              <span>Decrease</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendsChart;
