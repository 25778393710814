import React, { useEffect, useState } from "react";

import Table from "../../shared/tableScope";
import { sortBy } from "lodash";

const HighPerformanceAgents = ({
  dataSet = [],
  categories = [],
  loading = false,
}) => {
  const columns = [
    {
      name: "Agent name",
      key: "agentName",
      headerClassName: "bg-white text-[#383874] font-semibold text-left",
      className: " !text-[#425B76]",
    },
    {
      name: "Interactions Count",
      key: "numberOfInteractions",
      headerClassName: "bg-white text-[#383874] font-semibold text-center",
      className: "text-center !text-[#425B76]",
    },
    // {
    //   name: "RFC",
    //   key: "rfc",
    //   headerClassName: "bg-white text-[#383874] font-semibold text-center",
    //   className: "text-center !text-[#383874]",
    // },
    {
      name: "AHT",
      key: "ahtInSeconds",
      headerClassName: "bg-white text-[#383874] font-semibold text-center",
      className: "text-center",
      render: (text, record) => {
        const minutes = Math.floor(text / 60);
        const seconds = text % 60;

        return (
          <span
            className={`rounded-md px-1 py-1 font-bold text-[#383874] ${
              record.isMaxAHT && "bg-[#C0EAED]"
            }`}
          >
            {minutes} min {seconds} sec
          </span>
        );
      },
    },
  ];

  const updateColumns = (data = []) => {
    return data.map((c) => ({
      name: c.title,
      key: c.name,
      headerClassName: "bg-white text-[#383874] font-semibold text-center",
      className: "text-center",
      render: (text, record) => {
        return (
          <span
            className={`rounded-md px-1 py-1 font-bold text-[#383874] ${
              record[`isMax${c.id}`] && "bg-[#C0EAED]"
            }`}
          >
            {text}%
          </span>
        );
      },
    }));
  };

  useEffect(() => {
    if (categories && categories.length > 0) updateColumns();
  }, [categories]);

  const normalizeData = (data) => {
    if (data.length > 0) {
      const maxAHTRow = data.reduce((prev, cur) =>
        cur.ahtInSeconds < prev.ahtInSeconds ? cur : prev
      );

      let maxCategories = [];

      categories.forEach((c) => {
        const maxRow = data.filter(d => d.agentId !== maxAHTRow.agentId).reduce((prev, cur) =>
          cur[c.name] > prev[c.name] ? cur : prev
        );

        maxCategories.push({
          [`isMax${c.id}`]: true,
          ...maxRow,
        });
      });

      return sortBy(
        [{ ...maxAHTRow, isMaxAHT: true }, ...maxCategories],
        ["ahtInSeconds", ...categories.map((c) => c.name)]
      ).slice(0, 5);
    } else {
      return data;
    }
  };

  return (
    <>
      <h1 className="mb-6 px-4 text-2xl text-[#00BBA7]">
        High Performance Agents
      </h1>

      <Table
        royKey="id"
        loading={loading}
        rowHeight={10}
        columns={[...columns, ...updateColumns(categories || [])]}
        dataSet={[...normalizeData(dataSet || [])]}
      />
    </>
  );
};

export default HighPerformanceAgents;
