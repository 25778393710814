import React, { useEffect, useState } from "react";

import Dialog from "rc-dialog";
import Tooltip from "rc-tooltip";

// import down from "../../assets/images/icons/sort-arrow-down-active.svg";
// import up from "../../assets/images/icons/sort-arrow-up-active.svg";

const STATUS_STATES = [undefined, "ASC", "DESC"];

const TableHeaderColumn = ({
  column,
  setPage,
  filter = { query: undefined },
  isFirst,
  isLast,
  handleSortOrder,
  order,
}) => {
  return (
    !column.isHidden && (
      <ThComponent
        column={column}
        isFirst={isFirst}
        isLast={isLast}
        order={order}
        filter={filter}
        setPage={setPage}
        handleSortOrder={handleSortOrder}
      />
    )
  );
};

export default TableHeaderColumn;

const ThComponent = ({
  column,
  isFirst,
  isLast,
  order,
  setPage,
  filter = { query: undefined },
  handleSortOrder,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [destination, setDestination] = useState(
    STATUS_STATES.findIndex((s) => s === order)
  );

  const handleSortAToZ = () => {
    if (column.sortable) {
      if (destination === 1) setDestination(0);
      else setDestination(1);
    }
  };

  const handleSortZToA = () => {
    if (column.sortable) {
      if (destination === 2) setDestination(0);
      else setDestination(2);
    }
  };

  const resetSort = () => {
    setDestination(0);
  }

  useEffect(() => {
    if (handleSortOrder && column.key && column.sortable) {
      handleSortOrder(column.key, STATUS_STATES[destination]);
    }
    // eslint-disable-next-line
  }, [destination]);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <th
        scope="col"
        // eslint-disable-next-line
        className={`space-x-2 whitespace-nowrap bg-[#F8F5FD] px-3 py-4 text-sm font-normal capitalize text-[#425B76] rtl:space-x-reverse ${
          column.headerClassName || ""
        } ${isFirst ? "rounded-l-md" : ""} ${isLast ? "rounded-r-md" : ""}`}
      >
        {column.sortable || column.filter ? (
          <div className="relative inline-flex items-center space-x-2">
            <span className="inline-flex">{column.name}</span>

            <Tooltip
              trigger={
                STATUS_STATES[destination] ||
                typeof filter.query !== "undefined"
                  ? ["hover"]
                  : []
              }
              placement="bottom"
              prefixCls="filter-tooltip"
              showArrow={false}
              overlay={
                <div className="flex max-w-[300px] flex-col gap-2 divide-y divide-[#C0EAED] bg-[#FDF9FB] text-[#474E55]">
                  {column.sortable && STATUS_STATES[destination] && (
                    <div className="pt-2">
                      <span className="text-base font-semibold">
                        {STATUS_STATES[destination] === "ASC"
                          ? "Sort A to Z"
                          : "Sort Z to A"}
                      </span>
                    </div>
                  )}

                  <div className="pt-2">
                    {column.filter?.previewCustom ? (
                      <>
                        {React.cloneElement(column.filter?.previewTemplate, {
                          data: filter.query,
                        })}
                      </>
                    ) : (
                      <>
                        <span className="text-base font-semibold">
                          {column.name}:
                        </span>

                        <div className="mt-2 flex flex-col gap-1 font-normal">
                          {typeof filter.query !== "undefined" ? (
                            <>
                              {Array.isArray(filter.query) ? (
                                <>
                                  {column.filter?.previewTemplate ? (
                                    <>
                                      {React.cloneElement(
                                        column.filter?.previewTemplate,
                                        {
                                          data: [...filter.query],
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {filter.query.map((q, i) => (
                                        <div key={i} className="flex gap-2">
                                          <span>{q}</span>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {column.filter?.previewTemplate ? (
                                    <>
                                      {React.cloneElement(
                                        column.filter?.previewTemplate,
                                        {
                                          data: filter.query,
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <span className="capitalize">
                                      {filter.query + ""}
                                    </span>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>No Filter</>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              }
            >
              <button
                type="button"
                className={`relative inline-flex flex-shrink-0 cursor-pointer items-center justify-center rounded-md hover:bg-white ${
                  (destination && destination > 0) ||
                  typeof filter.query !== "undefined"
                    ? "bg-white"
                    : ""
                }`}
                onClick={() => setOpen(!isOpen)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#32A6AE]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>

                {(destination && destination > 0) ||
                typeof filter.query !== "undefined" ? (
                  <i className="absolute bottom-0 right-0 z-10 h-1.5 w-1.5 translate-x-1/2 translate-y-1/2 rounded-full bg-[#FF8419]"></i>
                ) : (
                  ""
                )}
              </button>
            </Tooltip>
          </div>
        ) : (
          <span className="inline-flex">{column.name}</span>
        )}
      </th>

      <Dialog
        visible={isOpen}
        className="h-auto !max-w-sm"
        onClose={handleCancel}
        closeIcon={() => <></>}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div className="divide-y divide-[#C0EAED] rounded-xl bg-[#FDF9FB] px-4 py-3 text-[#33475B]">
          {column.sortable && (
            <div className="flex flex-col divide-y divide-[#C0EAED]">
              <div
                className="flex cursor-pointer select-none items-center justify-between py-2"
                onClick={handleSortAToZ}
              >
                <div className="flex items-center justify-between gap-3">
                  <div className="flex h-8 w-8 items-center justify-center rounded-md bg-[#98DDE1] text-white">
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.95032 1H10.9005"
                        stroke="currentColor"
                        strokeWidth="1.66393"
                        strokeLinecap="round"
                      />
                      <path
                        d="M3.12134 5.94922H13.0217"
                        stroke="currentColor"
                        strokeWidth="1.66393"
                        strokeLinecap="round"
                      />
                      <path
                        d="M1 10.9004H15.1434"
                        stroke="currentColor"
                        strokeWidth="1.66393"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <span className={`${destination === 1 && "font-semibold"}`}>
                    Sort A to Z
                  </span>
                </div>
                {destination === 1 ? (
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13.5" cy="13.5" r="13.5" fill="#FF7700" />
                    <path
                      d="M7 14.5L10.5 18L19.5 9"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              <div
                className="flex cursor-pointer select-none items-center justify-between py-2"
                onClick={handleSortZToA}
              >
                <div className="flex items-center justify-between gap-3">
                  <div className="flex h-8 w-8 items-center justify-center rounded-md bg-[#98DDE1] text-white">
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.95032 10.9004H10.9005"
                        stroke="#FDF9FB"
                        strokeWidth="1.66393"
                        strokeLinecap="round"
                      />
                      <path
                        d="M3.12134 5.95117H13.0217"
                        stroke="#FDF9FB"
                        strokeWidth="1.66393"
                        strokeLinecap="round"
                      />
                      <path
                        d="M1 1H15.1434"
                        stroke="#FDF9FB"
                        strokeWidth="1.66393"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <span className={`${destination === 2 && "font-semibold"}`}>
                    Sort Z to A
                  </span>
                </div>
                {destination === 2 ? (
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13.5" cy="13.5" r="13.5" fill="#FF7700" />
                    <path
                      d="M7 14.5L10.5 18L19.5 9"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <>
            {column.filter ? (
              <div className="flex flex-col">
                {column.filter?.component
                  ? React.cloneElement(column.filter?.component, {
                      setPage,
                      columnName: column.name,
                      columnKey: column.key,
                      resetSort,
                    })
                  : ""}
              </div>
            ) : (
              ""
            )}
          </>
        </div>
      </Dialog>
    </>
  );
};
