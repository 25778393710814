import React from "react";

const BarChart = ({ data = [] }) => {
  return (
    <div className="space-y-1.5">
      {data.map((item, index) => (
        <div key={index} className="flex space-x-4 items-center">
          <div className="min-w-[2rem] w-[2rem] flex justify-center items-center text-xs overflow-visible relative">
            <span className="relative z-10 text-[#425B76]">{item.name}</span>
          </div>

          <div
            className="min-w-[5%] inline-flex space-x-2 rounded-[4px_8px_8px_4px] h-8"
            style={{
              background: item.color || "#64C18F",
              width: item.percentage + "%",
            }}
          />

          <span className="relative text-sm text-[#425B76] -left-2">{item.percentage}%</span>
        </div>
      ))}
    </div>
  );
};

export default BarChart;
