import React, { useState } from "react";

import Breadcrumbs from "../../components/shared/breadcrumbs/Breadcrumbs";
import JibePerformance from "../../components/system-dashboard/jibe-performance/Performance";
import SurvayActivity from "../../components/system-dashboard/survay-activity/SurvayActivity";
import QA from "../../components/system-dashboard/qa/QA";
import OverallPerformance from "../../components/system-dashboard/overall-performance/OverallPerformance";
import RangeSelect from "../../components/shared/DateRangePicker";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { getLogin } from "../../helpers/util";
import { Avatar } from "@agney/react-avatar";

const today = dayjs();
const lastweek = dayjs().subtract(1, "week");

const Overview = () => {
  const { name, email } = getLogin();

  const [range, setRange] = useState({
    from: {
      year: lastweek.year(),
      month: lastweek.month() + 1,
      day: lastweek.date(),
    },
    to: { year: today.year(), month: today.month() + 1, day: today.date() },
  });
  // const [dataset, setDataset] = useState([])

  const handleChangeDate = (value) => {
    setRange(value);
  };

  // const fetcher = async (params) => {
  //   setLoading(true)
  //   try {
  //     const { data } = await servicesServices.fetchBar(params)

  //     setDataset([...data].map((element, index) => ({ ...element, id: index })))
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // const changePeriod = (mode) => {
  //   // TODO:
  //   console.log("change mode", mode)
  // }

  // useEffect(() => {
  //   fetcher({})
  // }, [])

  return (
    <div className="flex flex-col space-y-8">
      <div className="relative">
        <div className="">
          <Breadcrumbs />
        </div>
        <div className="absolute right-0 top-0 z-10 px-2">
          <button type="button" className="p-4 pb-6 pt-2">
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.74981 0C7.9596 0 6.24271 0.711159 4.97684 1.97703C3.71097 3.2429 2.99981 4.95979 2.99981 6.75C2.99981 10.1395 2.27451 12.2635 1.59139 13.5159C1.2488 14.144 0.912733 14.5604 0.6741 14.8123C0.554587 14.9385 0.459002 15.0239 0.398582 15.0742C0.368362 15.0994 0.346915 15.1158 0.335647 15.1242L0.327015 15.1305C0.0567889 15.3149 -0.0629886 15.6536 0.0319951 15.9673C0.127769 16.2837 0.419314 16.5 0.749814 16.5H18.7498C19.0803 16.5 19.3719 16.2837 19.4676 15.9673C19.5626 15.6536 19.4428 15.3149 19.1726 15.1305L19.164 15.1242C19.1527 15.1158 19.1313 15.0994 19.101 15.0742C19.0406 15.0239 18.945 14.9385 18.8255 14.8123C18.5869 14.5604 18.2508 14.144 17.9082 13.5159C17.2251 12.2635 16.4998 10.1395 16.4998 6.75C16.4998 4.95979 15.7887 3.2429 14.5228 1.97703C13.2569 0.711159 11.54 0 9.74981 0ZM16.5914 14.2341C16.7485 14.5221 16.9065 14.7765 17.06 15H2.43963C2.59311 14.7765 2.75116 14.5221 2.90824 14.2341C3.72512 12.7365 4.49981 10.3605 4.49981 6.75C4.49981 5.35761 5.05294 4.02226 6.0375 3.03769C7.02207 2.05312 8.35743 1.5 9.74981 1.5C11.1422 1.5 12.4776 2.05312 13.4621 3.03769C14.4467 4.02225 14.9998 5.35761 14.9998 6.75C14.9998 10.3605 15.7745 12.7365 16.5914 14.2341ZM8.66856 19.3737C8.46072 19.0154 8.00178 18.8934 7.64349 19.1012C7.28519 19.3091 7.16322 19.768 7.37106 20.1263C7.6128 20.5431 7.95978 20.889 8.37725 21.1294C8.79473 21.3699 9.26804 21.4965 9.74981 21.4965C10.2316 21.4965 10.7049 21.3699 11.1224 21.1294C11.5398 20.889 11.8868 20.5431 12.1286 20.1263C12.3364 19.768 12.2144 19.3091 11.8561 19.1012C11.4978 18.8934 11.0389 19.0154 10.8311 19.3737C10.7212 19.5631 10.5635 19.7203 10.3737 19.8296C10.1839 19.9389 9.9688 19.9965 9.74981 19.9965C9.53083 19.9965 9.31568 19.9389 9.12592 19.8296C8.93616 19.7203 8.77845 19.5631 8.66856 19.3737Z"
                fill="#32A6AE"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between gap-4">
          <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full rounded-full border-2 border-[#57FFED] p-2">
            <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full">
              <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                <Avatar
                  text={name || "Bessie Cooper"}
                  alt={email || "willie.jennings@example.com"}
                  backgrounds={["#57FFED"]}
                  src=""
                  shape="circle"
                  className="absolute object-cover text-lg !font-normal text-white"
                  textColor={"#FFFFFF"}
                  textProcessor={(text) =>
                    text
                      .match(/(^\S\S?|\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()
                  }
                  // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                />
              </div>
            </div>
          </div>
          {/* <div className="flex h-16 w-16 items-center justify-between overflow-hidden rounded-full border-2 border-[#57FFED] p-2">
            <div
              className="h-full w-full rounded-full bg-[#57FFED]/20 bg-cover object-cover"
              alt=""
            />
          </div> */}
          <div className="flex flex-col gap-0.5">
            <p className="text-3xl">
              <span className="text-[#474E55]">Hi {name},</span>
              <span className="text-[#FF7700]"> Good Morning</span>
            </p>
            <p className="text-sm text-[#32A6AE]">
              Here is what’s happining today.
            </p>
          </div>
        </div>

        <RangeSelect value={range} onChange={handleChangeDate} />
      </div>

      <div className="!mt-4">
        <JibePerformance range={range} />
      </div>

      <div className="flex flex-col gap-8 xl:flex-row">
        <div className="basis-auto md:basis-1/2">
          <SurvayActivity
            rangeObj={range}
            range={
              !isEmpty(range.to) && !isEmpty(range.from)
                ? dayjs(
                    `${range.to.year}-${range.to.month}-${range.to.day}`
                  ).diff(
                    dayjs(
                      `${range.from.year}-${range.from.month}-${range.from.day}`
                    ),
                    "day"
                  )
                : 1
            }
          />
        </div>
        <div className="basis-auto md:basis-1/2">
          <QA
            rangeObj={range}
            range={
              !isEmpty(range.to) && !isEmpty(range.from)
                ? dayjs(
                    `${range.to.year}-${range.to.month}-${range.to.day}`
                  ).diff(
                    dayjs(
                      `${range.from.year}-${range.from.month}-${range.from.day}`
                    ),
                    "day"
                  )
                : 1
            }
          />
        </div>
      </div>

      <div>
        <OverallPerformance
          rangeObj={range}
          range={
            !isEmpty(range.to) && !isEmpty(range.from)
              ? dayjs(
                  `${range.to.year}-${range.to.month}-${range.to.day}`
                ).diff(
                  dayjs(
                    `${range.from.year}-${range.from.month}-${range.from.day}`
                  ),
                  "day"
                )
              : 1
          }
        />
      </div>
    </div>
  );
};

export default Overview;
