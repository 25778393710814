import { setLogin, setToken } from "../../helpers/util";

import React, { useState } from "react";
import authenticationServices from "../../services/authentication.services";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const {
    register,
    // formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      grant_type: "password",
      username: "",
      password: "",
    },
  });

  const onSubmit = async (values) => {
    try {
      setSubmitted(true);
      const { data } = await authenticationServices.login(values);

      setLogin(data);
      setToken(data);

      navigate("/dashboard/overview", { replace: true });
    } catch (error) {
      console.log("error", error);
      toast.error(error.message)
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div className="w-full max-w-sm">
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="flex flex-col">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logos/zacoustic-logo.jpeg`}
            alt="brand-logo zacoustic"
            className="mx-auto w-40"
          />

          <div className="mt-12 flex flex-col">
            <input
              type="text"
              {...register("username")}
              className="rounded-md border border-[#CACED8] bg-[#FCFAFA] px-4 py-2 placeholder:text-[#083A50]/70 focus:outline-none"
              placeholder="Email Address"
            />
          </div>

          <div className="mt-6 flex flex-col">
            <input
              type="password"
              {...register("password")}
              className="rounded-md border border-[#CACED8] bg-[#FCFAFA] px-4 py-2 placeholder:text-[#083A50]/70 focus:outline-none"
              placeholder="Password"
            />
          </div>

          <div className="mt-2 flex justify-end">
            <a href="" className="text-sm text-[#32A6AE] underline">
              Forgot password?
            </a>
          </div>
        </div>
        <div className="mt-8 flex w-full">
          <button
            type="submit"
            className={`flex items-center justify-center w-full rounded p-2 text-white ${
              submitted ? "bg-[#f70]/70" : "bg-[#f70]"
            }`}
            disabled={submitted}
          >
            {submitted && (
              <svg
                class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            <span>Login</span>
          </button>
        </div>
      </form>

      <div className="item-center relative my-16 flex h-0 w-full flex-col justify-center">
        <div className="absolute relative w-full border-t border-[#32A6AE]"></div>
        <span className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 bg-white p-2 px-6 text-lg font-bold text-[#7B93AF]">
          OR
        </span>
      </div>

      <div className="mt-8 flex w-full">
        <button
          type="submit"
          className="block flex w-full items-center justify-center gap-3 rounded border border-[#3C5A9A] bg-[#3C5A9A] p-2 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="34"
            viewBox="0 0 24 34"
            fill="none"
          >
            <path
              d="M17.631 0C11.718 0 8.25 2.46509 8.25 8.08198V13.024H0.75V18.944H8.25V33.152H15.75V18.944H21.75L23.25 13.024H15.75V9.08365C15.75 6.96784 16.6245 5.92 19.14 5.92H23.25V0.24272C22.539 0.166944 20.4675 0 17.631 0Z"
              fill="white"
            />
          </svg>
          <span>Sign up with Facebook</span>
        </button>
      </div>
    </div>
  );
};

export default Login;
