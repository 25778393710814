import React, {
  forwardRef,
  useEffect,
  useRef
} from "react"

// Input.propTypes = {
//     status?: Status,
//     value?: string,
//     id?: string,
//     hint?: any,
//     textAlign?: "left" | "right" | "center" | "justify" | "start" | "end",
//     name?: string,
//     readOnly?: boolean,
//     disabled?: boolean,
//     autoComplete?: "on" | "off",
//     label?: string,
//     hideLabel?: boolean,
//     pattern?: string,
//     checked?: boolean,
//     placeholder?: string,
//     maxLength?: number,
//     isRequired?: boolean,
//     icon?: ElementType | ReactNode,
//     htmlFor?: string,
//     invalid?: boolean,
//     className?: any,
//     indeterminate?: boolean,
//   }

const useCombinedRefs = (...refs) => {
  const targetRef = useRef(null)

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === "function") {
        ref(targetRef.current)
      } else {
        // @ts-ignore
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export const Input = forwardRef((props, ref) => {
  const {
    type = "text",
    disabled = false,
    readOnly = false,
    size = "sm",
    status,
    isRequired,
    textAlign = "left",
    hint,
    id = "",
    maxLength = 30,
    autoComplete = "off",
    placeholder,
    pattern,
    hideLabel,
    label,
    icon,
    onChange = () => {},
    checked = false,
    htmlFor = "",
    invalid,
    className = "",
    value,
    indeterminate,
    ...rest
  } = props

  const defaultRef = useRef(null)
  const combinedRef = useCombinedRefs(ref, defaultRef)

  useEffect(() => {
    if (combinedRef?.current) {
      combinedRef.current.indeterminate = indeterminate ?? false
    }
  }, [combinedRef, indeterminate, checked])

  if (type === "checkbox") {
    return (
      <div className="flex items-start">
        <label
          htmlFor={htmlFor}
          className="inline-flex cursor-pointer items-center space-x-2 truncate rtl:space-x-reverse"
        >
          <input
            id={id}
            type="checkbox"
            onChange={onChange}
            checked={checked}
            // @ts-ignore
            indeterminate={indeterminate}
            value={value}
            ref={combinedRef}
            className={`form-checkbox h-3.5 w-3.5 rounded text-[#CACED8] before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] indeterminate:border-[#CACED8] indeterminate:bg-[#CACED8] indeterminate:after:absolute indeterminate:after:ml-[2px] indeterminate:after:mt-[5px] indeterminate:after:w-[0.5rem] indeterminate:after:border-[0.125rem] indeterminate:after:border-l-0 indeterminate:after:border-t-0 indeterminate:after:border-solid indeterminate:after:border-white focus:ring-0 focus:ring-transparent disabled:opacity-50 rtl:indeterminate:after:ml-[0] rtl:indeterminate:after:mr-[2px]`}
            {...rest}
          />
          <label htmlFor={id} className="cursor-pointer select-none truncate">
            {label}
          </label>
        </label>
      </div>
    )
  }

  return (
    <>
      {label ? (
        <label
          htmlFor={htmlFor}
          className={
            `mb-1 block text-${size} font-medium text-[#7B93AF]`
            // {
            //   "text-gray-700": status === "default",
            //   "after:ml-0.5 after:text-red-500 after:content-['*']": isRequired,
            //   "text-green-700": status === "success",
            //   "text-amber-400": status === "warning",
            // }
          }
        >
          {label || ""}
        </label>
      ) : null}
      {/* <div className={classNames(`relative w-full`)}> */}
      {icon ? (
        <div
          className={`absolute inset-y-0 flex ${textAlign}-0 pointer-events-none items-center pr-2 rtl:pl-2 rtl:pr-0`}
        >
          {icon}
        </div>
      ) : null}
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
        autoComplete={autoComplete}
        placeholder={placeholder}
        pattern={pattern}
        aria-invalid={invalid}
        className={
          `w-full rounded-md px-3 py-1 ${size}:text-${size}
                block border leading-7 placeholder-gray-400 
                shadow-sm focus:border-[#CACED8] focus:outline-none focus:ring-1 
                focus:ring-[#CACED8] disabled:border-gray-200
                 disabled:bg-gray-50 disabled:text-gray-500 disabled:shadow-none ${className}`
          //          ,{
          //     "focus-visible placeholder-gray-400 focus:border-[#CACED8] focus:ring-[#CACED8] disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 disabled:shadow-none":
          //       status === "default",
          //     "focus-visible border border-red-500 text-red-600 focus:border-red-500 focus:ring-red-500":
          //       status === "error",
          //     "focus-visible border border-amber-500 text-amber-600 focus:border-amber-500 focus:ring-amber-500":
          //       status === "warning" && readOnly,
          //     "pr-5 pl-8 rtl:pl-8 rtl:pr-5": icon,
          //   },)
        }
        {...rest}
      />
      {hint ? (
        <p
          className={
            `mt-2 text-xs`
            //   , {
            //     "text-green-600": status === "success",
            //     "text-red-600": status === "error",
            //     "text-amber-400": status === "warning",
            //     "text-gray-400":
            //       status !== "warning" &&
            //       status !== "error" &&
            //       status !== "success",
            //   })
          }
        >
          {hint || ""}
        </p>
      ) : null}
      {/* </div> */}
    </>
  )
})

export default Input
