import * as FileSaver from "file-saver";
import { useState } from "react";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export function useExport({ fileName }) {
  const [dataset, setDataset] = useState([]);
  const [headers, setHeaders] = useState([]);

  function exportToCSV() {
    const ws = XLSX.utils.aoa_to_sheet([headers]);

    XLSX.utils.sheet_add_json(ws, dataset, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `${fileName}-${new Date().getTime()}` + fileExtension
    );
  }

  return { exportToCSV, setDataset, setHeaders };
}
