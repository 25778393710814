import React, { useRef } from "react";

import Response from "./Response";
import Tabs from "rc-tabs";
import Tooltip from "rc-tooltip";

const Responses = () => {
  const contentRef = useRef(null);

  return (
    <div className="relative">
      <span className="absolute top-2">Responses</span>

      <Tabs
        ref={contentRef}
        prefixCls="ink-tabs"
        defaultActiveKey="responses"
        className="!border-0"
        items={[
          {
            key: "responses",
            label: (
              <div className="flex items-center space-x-2 relative">
                <span>Responses</span>
                <Tooltip
                  placement="rightTop"
                  prefixCls="z-tooltip"
                  trigger={["hover"]}
                  showArrow
                  overlay={
                    <p>
                      Ea incididunt culpa cupidatat veniam excepteur dolore.
                      Labore consequat mollit aute cillum sint culpa mollit
                      cupidatat anim esse fugiat mollit commodo. Deserunt esse
                      ut do ad consectetur cupidatat in eiusmod. Nostrud nostrud
                      magna ipsum Lorem sint occaecat ut reprehenderit. Eiusmod
                      anim irure dolore deserunt anim laborum sint duis
                      adipisicing pariatur incididunt consectetur. Proident ut
                      nulla excepteur fugiat incididunt culpa dolore aliquip
                      exercitation velit sint Lorem.
                    </p>
                  }
                  getTooltipContainer={() => contentRef.current}
                >
                  <span>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="inline-block w-5 h-5 text-cyan-300"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </span>
                </Tooltip>
              </div>
            ),

            children: <Response />,
          },
          {
            key: "channel",
            label: (
              <div className="flex items-center space-x-2">
                <span>Channel</span>
                <Tooltip
                  placement="rightTop"
                  trigger={["hover"]}
                  prefixCls="z-tooltip"
                  showArrow
                  overlay={
                    <span>
                      Dolor non adipisicing voluptate sint et labore occaecat
                      cillum proident proident enim nulla ut. Voluptate
                      consequat commodo ipsum sit enim voluptate laboris laborum
                      do deserunt. Qui ipsum enim cillum minim ut. Exercitation
                      laboris duis quis et excepteur fugiat non exercitation
                      dolor tempor dolor id. Lorem adipisicing eiusmod in sint
                      ea culpa voluptate anim duis culpa et. Ut reprehenderit
                      sunt commodo mollit fugiat consequat.
                    </span>
                  }
                  getTooltipContainer={() => contentRef.current}
                >
                  <span>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="inline-block w-5 h-5 text-cyan-300"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </span>
                </Tooltip>
              </div>
            ),
            children: <>in Progress 2 </>,
          },
          {
            key: "rfc",
            label: (
              <div className="flex items-center space-x-2">
                <span>RFC</span>
                <Tooltip
                  placement="rightTop"
                  trigger={["hover"]}
                  prefixCls="z-tooltip"
                  showArrow
                  overlay={
                    <span>
                      Dolor non adipisicing voluptate sint et labore occaecat
                      cillum proident proident enim nulla ut. Voluptate
                      consequat commodo ipsum sit enim voluptate laboris laborum
                      do deserunt. Qui ipsum enim cillum minim ut. Exercitation
                      laboris duis quis et excepteur fugiat non exercitation
                      dolor tempor dolor id. Lorem adipisicing eiusmod in sint
                      ea culpa voluptate anim duis culpa et. Ut reprehenderit
                      sunt commodo mollit fugiat consequat.
                    </span>
                  }
                  getTooltipContainer={() => contentRef.current}
                >
                  <span>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="inline-block w-5 h-5 text-cyan-300"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </span>
                </Tooltip>
              </div>
            ),
            children: <>in Progress 3 </>,
          },
        ]}
      />
    </div>
  );
};

export default Responses;
