import Highcharts from "highcharts"
import { HighchartsReact } from "highcharts-react-official"
import React from "react"

const options = {
  title: {
    text: ""
  },

  credits: {
    enabled: false
  },

  legend: {
    enabled: false
  },

  tooltip: {
    valueDecimals: 2,
    valueSuffix: " TWh"
  },

  plotOptions: {
    series: {
      borderWidth: 0,
      colorByPoint: true,
      type: "pie",
      size: "100%",
      innerSize: "50%",
      dataLabels: {
        enabled: true,
        crop: false,
        distance: "-10%",
        style: {
          fontWeight: "bold",
          fontSize: "16px"
        },
        connectorWidth: 0
      }
    }
  },
  //   colors: ["#FCE700", "#F8C4B4", "#f6e1ea", "#B8E8FC", "#BCE29E"],
  series: [
    {
      type: "pie",
      name: "startYear",
      data: [10, 20, 50, 10]
    }
  ]
}

const RFC = () => {
  return (
    <div>
      <div className="flex ">
        <div className="w-1/2 flex-shrink-0">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        <div className="flex min-h-[300px] w-1/2 flex-shrink-0 items-center justify-center">
          <div className="flex flex-col text-[#425B76]">
            <span>Total ticket</span>
            <span>5.987,34</span>
          </div>
        </div>
      </div>

      <a
        href="/"
        className="mt-8 flex w-full items-center justify-center rounded-full bg-[#E6F7F8] py-3"
      >
        <span className="text-[#7B93AF]">Show survay Report</span>
      </a>
    </div>
  )
}

export default RFC
