import { NavLink, useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import routes from "../../../app/privateRoutes";
import { getLogin, setLogin, removeToken } from "../../../helpers/util";
import { Avatar } from "@agney/react-avatar";
import Tooltip from "rc-tooltip";

const Header = ({ setOpen, isOpen }) => {
  const toggleSidebar = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="sidebar-header relative z-0 h-[130px] px-4">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logos/zacoustic-logo.png`}
        alt="brand-logo zacoustic"
        className={`absolute left-0 top-2 z-10 ${isOpen ? "lg:left-[90px] lg:top-6": "lg:top-14"}`}
      />
      <div className="absolute bottom-0 left-0 right-0 mx-4 border-b border-[#C0EAED]" />

      <button
        type="button"
        className="lg:absolute left-4 top-4 z-10 hidden lg:block"
        onClick={toggleSidebar}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={`translate-all ${isOpen ? "" : "rotate-180"}`}
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="url(#paint0_linear_294_3033)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_294_3033"
              x1="8.57872"
              y1="6"
              x2="15.2177"
              y2="6.10969"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#32A6AE" />
              <stop offset="1" stop-color="#00BBA7" />
            </linearGradient>
          </defs>
        </svg>
      </button>
    </div>
  );
};
const Menu = ({ isOpen }) => {
  return (
    <div className="flex-grow overflow-hidden overflow-y-auto">
      <div className="py-12">
        <ul className="space-y-3">
          {[...routes]
            .filter((x) => x.type === "DASHBOARD")
            .map((route, index) => (
              <li key={index}>
                <NavLink
                  to={route.path}
                  //
                  className={({ isActive }) =>
                    isActive
                      ? "relative flex items-center justify-center space-x-3 px-4 py-2 font-bold text-[#33475B] before:absolute before:left-0 before:top-0 before:h-full before:w-1.5 before:rounded-r-lg before:bg-[#FF9538] lg:justify-start lg:px-6"
                      : "relative flex items-center justify-center space-x-3 px-4 py-2 text-[#33475B] lg:justify-start lg:px-6"
                  }
                >
                  {({ isActive }) => (
                    <>
                      {route.icon(isActive)}
                      <span
                        className={`hidden ${isOpen ? "lg:inline-block" : ""}`}
                      >
                        {route.title}
                      </span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
        </ul>

        <div className="mx-4 my-4 border-b border-[#C0EAED]" />

        <ul className="space-y-4">
          {[...routes]
            .filter((x) => x.type === "OTHER")
            .map((route, index) => (
              <li key={index}>
                <NavLink
                  to={route.path}
                  //
                  className={({ isActive }) =>
                    isActive
                      ? "relative flex items-center justify-center space-x-3 px-4 py-2 font-bold text-[#33475B] before:absolute before:left-0 before:top-0 before:h-full before:w-1.5 before:rounded-r-lg before:bg-[#FF9538] lg:justify-start lg:px-6"
                      : "relative flex items-center justify-center space-x-3 px-4 py-2 text-[#33475B] lg:justify-start lg:px-6"
                  }
                >
                  {({ isActive }) => (
                    <>
                      {route.icon(isActive)}
                      <span
                        className={`hidden ${isOpen ? "lg:inline-block" : ""}`}
                      >
                        {route.title}
                      </span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

const Footer = ({ isOpen }) => {
  const { name, email } = getLogin();

  const navigate = useNavigate();
  const containerRef = useRef(null);

  return (
    <div className="flex flex-col space-y-2 px-2 py-2 lg:px-4">
      <div className="flex items-center justify-center space-x-2 lg:justify-start">
        {/* <div className="h-[40px] w-[40px] flex-shrink-0 overflow-hidden rounded-full bg-neutral-300">
          <img
            // src="https://dummyimage.com/200x200.jpg?text=avatar"
            className="object-cover"
            alt=""
          />
        </div> */}
        <div className="flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center rounded-full">
          <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full">
            <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
              <Avatar
                text={name || "Bessie Cooper"}
                alt={email || "willie.jennings@example.com"}
                backgrounds={[
                  "#f28482",
                  "#84a59d",
                  "#b08968",
                  "#eb5e28",
                  "#b8c0ff",
                ]}
                src=""
                shape="circle"
                className="absolute object-cover text-xs !font-normal text-white"
                textColor={"#fff"}
                textProcessor={(text) =>
                  text
                    .match(/(^\S\S?|\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase()
                }
                // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
              />
            </div>
          </div>
        </div>
        <div className={`hidden flex-col truncate ${isOpen ? "lg:flex" : ""}`}>
          <span className="truncate text-sm font-bold text-[#425B76]">
            {name || "Bessie Cooper"}
          </span>
          <span className="truncate text-xs text-[#425B76]">
            {email || "willie.jennings@example.com"}
          </span>
        </div>
      </div>
      <div className="border-b border-[#C0EAED]" />
      <div
        ref={containerRef}
        className={`flex w-full flex-col-reverse items-center justify-center gap-y-3 ${
          isOpen ? "lg:flex-row lg:justify-between lg:gap-y-0 lg:space-x-3" : ""
        }`}
      >
        <div
          className={`flex w-full flex-col items-center justify-center space-y-3 ${
            isOpen
              ? "lg:flex-row lg:justify-start lg:space-x-3 lg:space-y-0"
              : ""
          }`}
        >
          <Tooltip
            trigger={["hover"]}
            // placement="top"
            // prefixCls="tooltip"
            getTooltipContainer={() => containerRef.current}
            showArrow
            overlay={<div>Customer Portal</div>}
          >
            <a
              href="https://support.zacoustic.com/_hcms/mem/login?redirect_url=https%3A%2F%2Fsupport.zacoustic.com%2Ftickets-view"
              className=""
              target="_blank"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icons/settings.svg`}
                alt=""
              />
            </a>
          </Tooltip>
          <Tooltip
            trigger={["hover"]}
            // placement="top"
            // prefixCls="tooltip"
            getTooltipContainer={() => containerRef.current}
            showArrow
            overlay={<div>Knowledge Base</div>}
          >
            <a
              href="https://support.zacoustic.com/"
              target="_blank"
              className=""
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icons/life-buoy.svg`}
                alt=""
              />
            </a>
          </Tooltip>
        </div>
        <button
          onClick={() => {
            setLogin({});
            removeToken();

            navigate("/", { replace: true });
          }}
          className={`flex gap-1 rounded-full px-4 py-2 text-sm text-[#F2547D] ${
            isOpen ? "lg:border lg:border-[#F2547D]" : ""
          }`}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="none"
            className="h-4 w-4"
          >
            <path
              d="M12.4444 4.44444L11.1911 5.69778L12.5956 7.11111H5.33333V8.88889H12.5956L11.1911 10.2933L12.4444 11.5556L16 8L12.4444 4.44444ZM1.77778 1.77778H8V0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H8V14.2222H1.77778V1.77778Z"
              fill="url(#paint0_linear_294_3028)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_294_3028"
                x1="-1.1234"
                y1="5.26027e-06"
                x2="16.566"
                y2="0.58454"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F2547D" />
                <stop offset="1" stop-color="#FF7959" />
              </linearGradient>
            </defs>
          </svg>
          <span className={`hidden ${isOpen ? "lg:inline-block" : ""}`}>Logout</span>
        </button>
        
      </div>
    </div>
  );
};

const Sidebar = {
  Header,
  Menu,
  Footer,
};

export default Sidebar;
