import React, { useEffect, useState } from "react";

import SplineChart from "./SplinChart";

const JIBE_PERFORMANCE_WARN_AMOUNT = (process.env.REACT_APP_JIBE_PERFORMANCE_WARN_AMOUNT || -5)

export default function ChartCard(props) {
  const {
    range = 1,
    chartName,
    data,
    color,
    colorGradient,
  } = props;

  const [upDownPercentAmount, setUpDownPercentAmount] = useState(0);
  const [percentAmount, setPercentAmount] = useState(0);
  const [name, setName] = useState("");

  useEffect(() => {
    if (chartName) {
      setName(chartName);
    }

    if (data && data.length) {
        setUpDownPercentAmount(data[data.length - 1].y - data[0].y);
        setPercentAmount(data[data.length - 1].y);
    }
  }, [props]);

  return (
    <fieldset
      className={`relative w-full rounded-lg border p-6 pb-2 max-w-[220px] mx-auto ${
        upDownPercentAmount < JIBE_PERFORMANCE_WARN_AMOUNT ? "border-[#F2547D]" : "border-transparent"
      }`}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="gap-2 flex">
          <div
            style={{
              marginRight: "auto",
            }}
          >
            <span style={{ marginBottom: "10px" }}>{name}</span>
            <div className="flex items-center gap-2 text-sm">
              <span style={{ padding: "0px" }}>
                {upDownPercentAmount > 0 ? (
                  <svg
                    height={"1rem"}
                    width={"1rem"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 25 25"
                    strokeWidth="4"
                    stroke="#11b4aa"
                    style={{ padding: "0px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                ) : upDownPercentAmount < 0 && (
                  <svg
                    height={"1rem"}
                    width={"1rem"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 25 25"
                    strokeWidth="4"
                    stroke="#fe765c"
                    style={{ padding: "0px", margin: "0px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
                    />
                  </svg>
                )}
              </span>
              <span
                style={{
                  color: "#98abc2",
                  margin: "0 0 0 2px"
                }}
              >
                {upDownPercentAmount}%
              </span>
              <span
                className="whitespace-nowrap"
                style={{
                  backgroundColor: "#f2f6f9",
                  borderRadius: "5px",
                  color: "#98abc2",
                  padding: "2px 5px",
                  margin: "0px",
                }}
              >
                {range <= 1 ? "24 h" : `${range} d`}
              </span>
            </div>
          </div>
          <div
            style={{
              direction: "rtl",
              fontSize: "24px",
              fontWeight: "normal",
            }}
          >
            {percentAmount}
            <span
              style={{
                marginLeft: "5px",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              %
            </span>
          </div>
        </div>
        <div className="px-6">
          <SplineChart
            data={data}
            color={color}
            name={chartName}
            colorGradient={colorGradient}
          />
        </div>
      </div>
      <legend className="absolute bottom-0 left-3 translate-y-1/2 bg-white px-0.5">
        {upDownPercentAmount < JIBE_PERFORMANCE_WARN_AMOUNT && (
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-8 w-8 text-[#F2547D]"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        )}
      </legend>
    </fieldset>
  );
}
