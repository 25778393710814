import React, { useEffect, useRef, useState } from "react";

import AgentSelect from "./AgentSelect";
import ChannelSelect from "./ChannelSelect";
import Export from "./Export";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import RFCSelect from "./RFCSelect";
import Screenshot from "../../jibe-dashboard/team-comparison-chart/Screenshot";
import TeamSelect from "./TeamSelect";
import exporting from "highcharts/modules/exporting";
import fullScreen from "highcharts/modules/full-screen";
import noDataToDisplay from "highcharts/modules/no-data-to-display";
import offlineExporting from "highcharts/modules/offline-exporting";
import { useFullscreen } from "../../../hooks/useFullscreen";

HighchartsMore(Highcharts);
fullScreen(Highcharts);
exporting(Highcharts);
offlineExporting(Highcharts);
noDataToDisplay(Highcharts);

const colors = ["#f28482", "#84a59d", "#b08968", "#eb5e28", "#b8c0ff"];

let isPanning = false;
let startX = 0;
let startY = 0;

let currentMinX = 0,
  currentMaxX = 0,
  currentMinY = 0,
  currentMaxY = 0;

const options = {
  chart: {
    // zoomType: "xy",
    panning: true,
    panKey: "shift",
    type: "scatter",
    // borderWidth: 2,
    // borderRadius: 20,
    // borderColor: "#c4fff8",
  },
  exporting: {
    enabled: false,
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      verticalAlign: "top",
    },
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    zoomEnabled: true,
    events: {
      afterSetExtremes: function (e) {
        currentMinX = e.min;
        currentMaxX = e.max;
      },
    },
    className: "highcharts-position",
    resize: {
      enabled: true,
    },
    title: {
      text: "",
    },
    labels: {
      align: "center",
      // rotation: 0,
      useHTML: true,
      // x: "",
      y: 12,
      formatter() {
        const minutes = Math.floor(this.value / 60);
        const seconds = this.value % 60;

        return `<div class="text-[#425B76] bg-[#E6F7F8] py-2 px-2">${minutes}' ${
          seconds < 10 ? "0" + seconds : seconds
        }"</div>`;
      },
    },
    gridLineWidth: 0,
    html: true,
    min: 0,
    tickWidth: 0,
    tickInterval: 1,
    // tickInterval: 8, // time / 2
    tickColor: "#E6F7F8",
    lineWidth: 1,
    lineColor: "#E6F7F8",
  },
  yAxis: {
    events: {
      afterSetExtremes: function (e) {
        currentMinY = e.min;
        currentMaxY = e.max;
      },
    },
    resize: {
      enabled: true,
    },
    zoomEnabled: true,
    startOnTick: true,
    endOnTick: true,
    gridLineWidth: 0,
    minorGridLineWidth: 1,
    minorTickInterval: 50,
    title: {
      text: "",
    },
    tickInterval: 20,
    labels: {
      format: "{value}%",
      x: -25,
      y: 5,
    },
  },
  // stacking: "percent",
  legend: {
    enabled: false,
  },
  plotOptions: {
    scatter: {
      event: {
        load: function () {
          //     var chart = this.chart;
          //     var xAxis = chart.xAxis[0];
          //     var firstLabel = xAxis.ticks[xAxis.tickPositions[0]].label;
          //     var lastLabel =
          //       xAxis.ticks[xAxis.tickPositions[xAxis.tickPositions.length - 1]]
          //         .label;
          //     var containerWidth = xAxis.width;
          //     var labelWidth =
          //       lastLabel.getBBox().width - firstLabel.getBBox().width;
          //     var containerPadding = (containerWidth - labelWidth) / 2;
          //     xAxis.labelGroup.element.setAttribute(
          //       "transform",
          //       "translate(" + containerPadding + ")"
          //     );
        },
      },
      series: {
        pointWidth: 20, // Adjust the width of the data points if needed
      },
      marker: {
        radius: 2.5,
        symbol: "circle",
        states: {
          hover: {
            enabled: true,
            lineColor: "rgb(100,100,100)",
          },
        },
      },
      states: {
        hover: {
          marker: {
            enabled: false,
          },
        },
      },
      jitter: {
        x: 0.005,
      },
    },
  },
  tooltip: {
    useHTML: true,
    headerFormat: "",
    footerFormat: null,
    enabled: true,
    shape: "rect",
    borderRadius: 0,
    borderWidth: 0,
    borderColor: "transparent",
    backgroundColor: "transparent",
    padding: 0,
    distance: 10,
    className: "p-0 m-0",
    style: {
      padding: 0,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  credits: {
    enabled: false,
  },
  JSX: {
    throwIfNamespace: false,
  },
  series: [],
};

export default function ScatterPlotChart({
  loading = false,
  dataSet = [],
  categories = [],
}) {
  const [disabled, setDisabled] = useState([]);
  const [mouse, setMouse] = useState(null);
  const { enterFullscreen, fullscreenActive } = useFullscreen();

  const chartRef = useRef(null);
  const boxRef1 = useRef(null);
  const chartContainerRef = useRef(null);
  const screenShotRef = useRef(null);

  const handleToggleLegend = (name) => {
    const point = chartRef.current.chart.series.find((x) => x.name === name);
    if (point.visible) {
      setDisabled([...disabled, name]);
      point.setVisible(false);
    } else {
      setDisabled([...disabled].filter((x) => x !== name));
      point.setVisible(true);
    }
  };

  useEffect(() => {
    if (chartRef) {
      if (loading) {
        chartRef.current.chart.showLoading("Loading");
      } else {
        chartRef.current.chart.hideLoading();
      }
    }
  }, [loading]);

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    const chart = chartRef.current.chart;

    if (chartRef.current && dataSet.length > 0) {
      const min = Math.min(...dataSet.map((item) => item.ahtInSeconds));
      const max = Math.max(...dataSet.map((item) => item.ahtInSeconds));
      const mid = (max + 5 + (min - 5)) / 2;

      categories.forEach((category) => {
        chart.addSeries({
          ...category,
          data: dataSet.reduce((result, item) => {
            result = [
              ...result,
              {
                x: item.ahtInSeconds,
                y: item[category.name],
                name: item.agentName,
                // iconUrl: require("/assets/images/avatar/avatar.jpg"),
              },
            ];
            return result;
          }, []),
        });
      });

      chart.update({
        ...options,
        tooltip: {
          ...options.tooltip,
          pointFormatter: function () {
            const iconUrl = this.series.options.data[this.index].iconUrl;
            const pointName = this.series.options.data[this.index].name;

            const minutes = Math.floor(this.x / 60);
            const seconds = this.x % 60;

            return (
              '<div class="flex bg-white shadow-lg p-1 pr-3 border rounded-xl rounded-bl-3xl rounded-tl-none gap-3">' +
              '<div class="w-12 h-12 rounded-full rounded-tl-none overflow-hidden">' +
              (iconUrl
                ? '<img class="object-fit w-full h-full" src="' +
                  iconUrl +
                  '" />'
                : '<div class="object-fit w-full h-full text-white font-bold text-lg flex justify-center items-center" style="backgroundColor: ' +
                  colors[Math.floor(Math.random() * 5)] +
                  '"><span>' +
                  pointName
                    .match(/(^\S\S?|\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase() +
                  "</span></div>") +
              "</div>" +
              '<div class="flex flex-col">' +
              '<div class="text-[#7b93af] text-lg capitalize mb-1">' +
              pointName +
              "</div>" +
              '<div class="text-[#33475b]">' +
              categories[this.series.index].title +
              " <b>" +
              this.y +
              "% </b>" +
              "<span> | </span>" +
              "AHT " +
              `<b>${minutes}.${seconds} min</b>` +
              "</div>" +
              "</div></div>"
            );
          },
        },
        xAxis: {
          ...options.xAxis,
          labels: {
            rotation: 0,
            ...options.xAxis.labels,
            step: max + 5 - (min - 5),
            formatter() {
              return `<div class="text-[#425B76] bg-[#E6F7F8] py-4 px-2 text-center w-[calc(785px/2)] ${
                this.isFirst
                  ? "translate-x-1/2 rounded-bl-lg left-0"
                  : this.isLast
                  ? "-translate-x-1/2 rounded-br-lg right-0"
                  : ""
              }">
              ${
                this.isFirst
                  ? `${Math.floor(min / 60)}:${
                      min % 60 < 10 ? "0" + (min % 60) : min % 60
                    } ~ ${Math.floor(mid / 60)}:${
                      mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
                    }`
                  : this.isLast
                  ? `${Math.floor(mid / 60)}:${
                      mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
                    } ~ ${Math.floor(max / 60)}:${
                      max % 60 < 10 ? "0" + (max % 60) : max % 60
                    }`
                  : ""
              }
              </div>`;
            },
          },
          min: min - 5,
          max: max + 5,
          plotLines: [
            {
              color: "#eeeeee",
              width: 1,
              value: mid,
            },
          ],
        },
      });
    }
  }, [dataSet]);

  const handleZoomIn = () => {
    console.log("Zoom In Clicked");
    const chart = chartRef.current.chart;

    const xAxis = chart.xAxis[0];
    const yAxis = chart.yAxis[0];

    const newMinX = (currentMaxX - currentMinX) * 0.25 + currentMinX;
    const newMaxX = (currentMaxX - currentMinX) * 0.75 + currentMinX;
    const newMinY = (currentMaxY - currentMinY) * 0.25 + currentMinY;
    const newMaxY = (currentMaxY - currentMinY) * 0.75 + currentMinY;

    console.log(
      "Setting New Extremes for Zoom In:",
      newMinX,
      newMaxX,
      newMinY,
      newMaxY
    );

    xAxis.setExtremes(newMinX, newMaxX);
    yAxis.setExtremes(newMinY, newMaxY);

    // const min = newMinX;
    // const max = newMaxX;
    // const mid = (max + 5 + (min - 5)) / 2;

    // debugger;

    // chart.update({
    //   ...options,
    //   xAxis: {
    //     ...options.xAxis,
    //     labels: {
    //       rotation: 0,
    //       ...options.xAxis.labels,
    //       // step: max + 5 - (min - 5),
    //       formatter() {
    //         // const minutes = Math.floor(this.value / 60);
    //         // const seconds = this.value % 60;
    //         // this.isFirst
    //         console.log("asdsdsads", this);

    //         return `<div class="text-[#425B76] bg-[#E6F7F8] py-4 px-2 text-center w-[calc(785px/2)] ${
    //           this.isFirst
    //             ? "translate-x-1/2 rounded-bl-lg"
    //             : this.isLast
    //             ? "-translate-x-1/2 rounded-br-lg"
    //             : ""
    //         }">
    //         ${
    //           this.isFirst
    //             ? `${Math.floor(min / 60)}:${
    //                 min % 60 < 10 ? "0" + (min % 60) : min % 60
    //               } ~ ${Math.floor(mid / 60)}:${
    //                 mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
    //               }`
    //             : this.isLast
    //             ? `${Math.floor(mid / 60)}:${
    //                 mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
    //               } ~ ${Math.floor(max / 60)}:${
    //                 max % 60 < 10 ? "0" + (max % 60) : max % 60
    //               }`
    //             : ""
    //         }
    //         </div>`;
    //       },
    //     },
    //   },
    // });
  };

  const handleZoomOut = () => {
    console.log("zoom out");
    const chart = chartRef.current.chart;
    chart.zoomOut();

    const xAxis = chart.xAxis[0];
    const yAxis = chart.yAxis[0];

    // const newMinX = (currentMaxX - currentMinX) * 0.25 + currentMinX
    // const newMaxX = (currentMaxX - currentMinX) * 0.75 + currentMinX
    // const newMinY = (currentMaxY - currentMinY) * 0.25 + currentMinY
    // const newMaxY = (currentMaxY - currentMinY) * 0.75 + currentMinY

    // xAxis.setExtremes(newMinX, newMaxX);
    // yAxis.setExtremes(newMinY, newMaxY);

    // const min = newMinX;
    // const max = newMaxX;
    // const mid = (max + 5 + (min - 5)) / 2;

    // chart.update({
    //   ...options,
    //   xAxis: {
    //     ...options.xAxis,
    //     labels: {
    //       rotation: 0,
    //       ...options.xAxis.labels,
    //       step: max + 5 - (min - 5),
    //       formatter() {
    //         // const minutes = Math.floor(this.value / 60);
    //         // const seconds = this.value % 60;
    //         return `<div class="text-[#425B76] bg-[#E6F7F8] py-4 px-2 text-center w-[calc(785px/2)] ${
    //           this.isFirst
    //             ? "translate-x-1/2 rounded-bl-lg"
    //             : this.isLast
    //             ? "-translate-x-1/2 rounded-br-lg"
    //             : ""
    //         }">
    //         ${
    //           this.isFirst
    //             ? `${Math.floor(min / 60)}:${
    //                 min % 60 < 10 ? "0" + (min % 60) : min % 60
    //               } ~ ${Math.floor(mid / 60)}:${
    //                 mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
    //               }`
    //             : this.isLast
    //             ? `${Math.floor(mid / 60)}:${
    //                 mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
    //               } ~ ${Math.floor(max / 60)}:${
    //                 max % 60 < 10 ? "0" + (max % 60) : max % 60
    //               }`
    //             : ""
    //         }
    //         </div>`;
    //       },
    //     },
    //     min: min - 5,
    //     max: max + 5,
    //     plotLines: [
    //       {
    //         color: "#eeeeee",
    //         width: 1,
    //         value: mid,
    //       },
    //     ],
    //   },
    // });
  };

  const isMouseInBound = (e) => {
    if (boxRef1 && boxRef1.current) {
      const eleBounds = boxRef1.current.getBoundingClientRect();
      let ret = false;
      if (e.clientX >= eleBounds.left && e.clientX <= eleBounds.right) {
        boxRef1.current?.classList.add(
          mouse === "HAND"
            ? "cursor-grab"
            : mouse === "ZOOM_IN"
            ? "cursor-zoom-in"
            : mouse === "ZOOM_OUT"
            ? "cursor-zoom-out"
            : "a"
        );
        ret = true;
      } else {
        boxRef1.current.classList.remove("cursor-grab");
        boxRef1.current.classList.remove("cursor-zoom-in");
        boxRef1.current.classList.remove("cursor-zoom-out");
        ret = false;
      }
    }
  };
  const [agentSelected, setAgentSelected] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);

  useEffect(() => {
    const chart = chartRef.current.chart;

    if (agentSelected.length > 0 || teamSelected.length > 0) {
      const selectedResult = [];
      agentSelected.length > 0 &&
        agentSelected.map((select) => {
          dataSet.map((item) => {
            item.agentName === select.name && selectedResult.push(item);
          });
        });
      teamSelected.length > 0 &&
        teamSelected.map((select) => {
          select.users.map((user) => {
            dataSet.map((item) => {
              item.agentName === user.name && selectedResult.push(item);
            });
          });
        });
      const result = categories.map((category) => {
        const data = selectedResult.reduce((result, item) => {
          result = [
            ...result,
            {
              x: item.ahtInSeconds,
              y: item[category.name],
              name: item.agentName,
              // iconUrl: require("/assets/images/avatar/avatar.jpg"),
            },
          ];
          return result;
        }, []);

        return {
          ...category,
          data: data,
        };
      });

      if (chartRef.current) {
        chart.update({
          ...options,
          series: result,
        });
      }
    } else {
      const min = Math.min(...dataSet.map((item) => item.ahtInSeconds));
      const max = Math.max(...dataSet.map((item) => item.ahtInSeconds));
      const mid = (max + 5 + (min - 5)) / 2;
      const seriesResult = categories.map((category) => {
        const data = dataSet.reduce((result, item) => {
          result = [
            ...result,
            {
              x: item.ahtInSeconds,
              y: item[category.name],
              name: item.agentName,
              // iconUrl: require("/assets/images/avatar/avatar.jpg"),
            },
          ];
          return result;
        }, []);

        return {
          ...category,
          data: data,
        };
      });

      if (chartRef.current && seriesResult.length > 0) {
        chart.update({
          ...options,
          series: seriesResult,
          xAxis: {
            ...options.xAxis,
            labels: {
              rotation: 0,
              ...options.xAxis.labels,
              step: max + 5 - (min - 5),
              formatter() {
                return `<div class="text-[#425B76] bg-[#E6F7F8] py-4 px-2 text-center w-[calc(785px/2)] ${
                  this.isFirst
                    ? "translate-x-1/2 rounded-bl-lg left-0"
                    : this.isLast
                    ? "-translate-x-1/2 rounded-br-lg right-0"
                    : ""
                }">
              ${
                this.isFirst
                  ? `${Math.floor(min / 60)}:${
                      min % 60 < 10 ? "0" + (min % 60) : min % 60
                    } ~ ${Math.floor(mid / 60)}:${
                      mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
                    }`
                  : this.isLast
                  ? `${Math.floor(mid / 60)}:${
                      mid % 60 < 10 ? "0" + (mid % 60) : mid % 60
                    } ~ ${Math.floor(max / 60)}:${
                      max % 60 < 10 ? "0" + (max % 60) : max % 60
                    }`
                  : ""
              }
              </div>`;
              },
            },
            min: min - 5,
            max: max + 5,
            plotLines: [
              {
                color: "#eeeeee",
                width: 1,
                value: mid,
              },
            ],
          },
        });
      }
    }
  }, [agentSelected, teamSelected]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center justify-between'>
        <div className='inline-flex flex-wrap gap-2'>
          {/* <RFCSelect /> */}
          {/* <ChannelSelect /> */}
          <TeamSelect
            teamSelected={teamSelected}
            setTeamSelected={setTeamSelected}
          />
          <AgentSelect
            agentSelected={agentSelected}
            setAgentSelected={setAgentSelected}
          />
        </div>

        <div className='inline-flex items-center gap-4 self-end py-2'>
          {!fullscreenActive && (
            <button
              type='button'
              className='text-cyan-400'
              onClick={enterFullscreen}
            >
              <svg
                stroke='currentColor'
                fill='none'
                strokeWidth='2'
                viewBox='0 0 24 24'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='h-5 w-5 cursor-pointer text-cyan-500'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3'></path>
              </svg>
            </button>
          )}

          <Screenshot screenShotRef={screenShotRef} />

          <Export dataset={[...dataSet]} />
        </div>
      </div>

      <div
        className='flex w-full flex-col gap-4 overflow-hidden rounded-[20px] border-2 border-[#c4fff8]'
        ref={screenShotRef}
      >
        <div className='flex w-full items-center justify-between gap-2 p-4'>
          {/* Legends */}
          <div className='inline-flex items-center gap-5'>
            {categories.map((key) => (
              <div
                key={key.name}
                className={`inline-flex cursor-pointer items-center gap-2 text-[#425B76] ${
                  [...disabled].includes(key.name) ? "line-through" : ""
                }`}
                onClick={() => handleToggleLegend(key.name)}
              >
                <span
                  className={`h-4 w-4 rounded-full`}
                  style={{ background: key.color }}
                />
                <span className=''>{key.title}</span>
              </div>
            ))}
          </div>

          {/* Actions */}

          <div
            className='inline-flex items-center gap-3'
            ref={chartContainerRef}
          >
            <button
              type='button'
              className='rounded-md bg-[#E6F7F8] p-1'
              id='zoomInButton'
              onClick={() => {
                setMouse("ZOOM_IN");
                handleZoomIn();
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                // stroke='#80dcd3'
                // stroke={stroke}
                stroke={"#02baa7"}
                className='h-5 w-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6'
                />
              </svg>
            </button>
            <button
              type='button'
              className='rounded-md bg-[#E6F7F8] p-1'
              id='zoomOutButton'
              onClick={() => {
                setMouse("ZOOM_OUT");
                handleZoomOut();
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke={"#02baa7"}
                className='h-5 w-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6'
                />
              </svg>
            </button>
            <button
              type='button'
              className='rounded-md bg-[#E6F7F8] p-1'
              id='handButton'
              onClick={() => {
                setMouse("HAND");
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke={"#02baa7"}
                className='h-5 w-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002'
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          className='chart-drag-container block h-[400px]'
          // onMouseEnter={isMouseInBound}
          onMouseEnter={isMouseInBound}
          onMouseLeave={isMouseInBound}
          id='boxRef1'
          ref={boxRef1}
        >
          <HighchartsReact
            className='w-full overflow-visible'
            highcharts={Highcharts}
            options={{
              options,
            }}
            ref={chartRef}
          />
        </div>
      </div>
    </div>
  );
}
