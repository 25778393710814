import request from "../helpers/request";

function login(values) {
  return request
    // .withHeaders({ "Content-Type": "application/json;utf-8" })
    .withHeaders({ "Content-Type": "application/x-www-form-urlencoded;utf-8" })
    .build()
    .post(`/Token`, values);
}

function logout() {
    
}

const authenticationServices = {
  login,
  logout,
};

export default authenticationServices;
