import React, { useEffect, useState } from "react";

import Drawer from "react-modern-drawer";
import HighPerformanceAgents from "./HighPerformanceAgents";
import LowPerformanceAgents from "./LowPerformanceAgents";
import ScatterPlotChart from "./ScatterPlotChart";
import outliersServices from "../../../services/outliers.services";
import servicesServices from "../../../services/services.services";

const colors = ["#f28482", "#84a59d", "#b08968", "#eb5e28", "#b8c0ff"];

const Button = ({ toggleDrawer }) => {
  return (
    <button
      type="button"
      className="btn btn-secondary btn-pill !gap-6 !p-3 !px-5"
      onClick={toggleDrawer}
    >
      <span className="cursor-pointer">Outlier</span>
    </button>
  );
};

const Box = ({ isOpen, handleClose, range }) => {
  const [categories, setCategories] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const fetchSurveyQuestions = async () => {
    setLoading1(true);
    try {
      const { data } = await servicesServices.fetchSurveyQuestions({});
      // setKeys([
      //   { name: "ASAT", id: "ASAT", color: "#fe9f4b" },
      //   { name: "RES", id: "RES", color: "#935ad8" },
      // ]);
      setCategories(
        data.result.map((c, index) => ({
          name: c.internalName,
          title: c.shortName,
          id: c.shortName,
          color: colors[index],
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const fetchOutliers = async (params) => {
    setLoading2(true);
    try {
      const { data } = await outliersServices.fetchOutliers(params);
      setDataSet(data.result);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (!loading1 && categories.length > 0)
      fetchOutliers({
        fromDate: `${range.from.month}/${range.from.day}/${range.from.year}`,
        toDate: `${range.to.month}/${range.to.day}/${range.to.year}`,
      });
  }, [loading1]);

  useEffect(() => {
    fetchSurveyQuestions();
  }, []);

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      direction="right"
      //   className="max-h-screen overflow-hidden overflow-y-auto rounded-l-lg !bg-[#FDF9FB] pb-5"
      className="max-h-screen overflow-hidden overflow-y-auto rounded-l-lg !bg-white pb-5"
      size={900}
      enableOverlay={true}
      overlayOpacity={0.1}
    >
      <h1 className="p-4 text-2xl text-[#474E55]">Agent Performance</h1>

      <div className="p-4">
        <ScatterPlotChart
          loading={loading1 || loading2}
          categories={categories}
          dataSet={dataSet}
        />
      </div>

      <div className="mt-16 p-4">
        <HighPerformanceAgents
          loading={loading1 || loading2}
          categories={categories}
          dataSet={dataSet}
        />
      </div>
      <div className="mt-5 p-4">
        <LowPerformanceAgents
          loading={loading1 || loading2}
          categories={categories}
          dataSet={dataSet}
        />
      </div>
    </Drawer>
  );
};

const AgentPerformance = {
  Button,
  Box,
};

export default AgentPerformance;
