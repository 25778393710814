import React, { useEffect, useState } from "react";
import { fetchAgents, setData } from "../coachingSlice";
import { useDispatch, useSelector } from "react-redux";

import { Avatar } from "@agney/react-avatar";
import Table from "../../shared/tableScope";
import dayjs from "dayjs";
import servicesServices from "../../../services/services.services";
import { useLocation } from "react-router-dom";

const Agent = () => {
  const [loadingShortNames, setLoadingShortNames] = useState(false);
  const [shortNames, setShortNames] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const dispatch = useDispatch();

  const { loading, filters, dataSet, originalDataSet, totalElements } =
    useSelector((state) => state.coaching);

  const fetchColumns = async () => {
    try {
      setLoadingShortNames(true);
      const { data } = await servicesServices.fetchSurveyQuestions();
      setShortNames(
        data.result.reduce((result, item) => {
          result = [
            ...result,
            {
              name: `Pre-${item.shortName}`,
              key: `sessionsDetail[].${item.internalName}BeforeCoaching`,
              headerClassName: "text-center",
              sortable: true,
              //   filter: {
              //     // overview: <OverviewFilterStatus />,
              //     component: <FilterStatus />,
              //   },
              render: (value, record) => (
                <div className="flex justify-center">
                  {record.sessionsDetail && record.sessionsDetail.length > 0
                    ? Number(
                        record.sessionsDetail[0][
                          `${item.internalName}BeforeCoaching`
                        ] || ""
                      )
                    : ""}
                </div>
              ),
            },
            {
              name: `Post-${item.shortName}`,
              key: `sessionsDetail[].${item.internalName}AfterCoaching`,
              headerClassName: "text-center",
              sortable: true,
              //   filter: {
              //     // overview: <OverviewFilterStatus />,
              //     component: <FilterStatus />,
              //   },
              render: (value, record) => (
                <div className="flex justify-center">
                  {record.sessionsDetail && record.sessionsDetail.length > 0
                    ? Number(
                        record.sessionsDetail[0][
                          `${item.internalName}AfterCoaching`
                        ] || ""
                      )
                    : ""}
                </div>
              ),
            },
          ];

          return result;
        }, [])
      );
    } catch (error) {
    } finally {
      setLoadingShortNames(false);
    }
  };

  useEffect(() => {
    dispatch(
      fetchAgents({ page: Number(queryParams.get("page") || 1), isAgent: true })
    );

    fetchColumns();
    // eslint-disable-next-line
  }, [search]);

  return (
    <div>
      <div className="">
        <Table
          filters={{ ...filters }}
          rowHeight={75} 
          showFooter={true}
          royKey="agentId"
          expandable={{
            rowExpandable: (record) => {
              // console.log(record.coachingSessionCount);
              return record && Number(record.coachingSessionCount) > 1;
            },
            expandedRowRender: ({ ...record }) => {
              let tmp = record.sessionsDetail;

              if (tmp.length > 0) {
                // debugger
                tmp = tmp.slice(1);
              }

              return (
                <div className="-mx-3 -my-3">
                  <table className="w-full">
                    <tbody>
                      {tmp.map((detail) => (
                        <tr>
                          <td className="invisible w-44 whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            <div className="inline-flex items-center space-x-2">
                              <div className="mx-auto flex h-[54px] w-[54px] items-center justify-center rounded-full bg-[#FFCB9E] p-[2px]">
                                <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white p-[5px]">
                                  <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                                    <Avatar
                                      text={record.agentName || ""}
                                      // alt={record.userName}
                                      backgrounds={[
                                        "#f28482",
                                        "#84a59d",
                                        "#b08968",
                                        "#eb5e28",
                                        "#b8c0ff",
                                      ]}
                                      src=""
                                      shape="circle"
                                      className="absolute object-cover text-xs !font-normal text-white"
                                      textColor={"#fff"}
                                      textProcessor={(text) =>
                                        text
                                          .match(/(^\S\S?|\b\S)?/g)
                                          .join("")
                                          .match(/(^\S|\S$)?/g)
                                          .join("")
                                          .toUpperCase()
                                      }
                                      // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div className="inline-flex text-sm text-[#33475B]">
                                  {record.agentName || ""}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="invisible w-20 whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            {record.coachingSessionCount}
                          </td>
                          <td className="invisible w-36 whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            {record.agentTeam}
                          </td>
                          <td className="w-36 whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            {detail.reasonForContact}
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            {dayjs(detail.coachingSessionDate).format(
                              "MM-DD-YYYY"
                            ) || ""}
                          </td>
                          {shortNames.map((shortName) => (
                            <td
                              className="w-[120px] whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900"
                              key={detail.key}
                            >
                              <div className="">
                                {
                                  detail[
                                    shortName.key.replace(
                                      "sessionsDetail[].",
                                      ""
                                    )
                                  ]
                                }
                              </div>
                            </td>
                          ))}

                          <td className="w-[120px] whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            {detail.ahtBeforeCoaching}
                          </td>
                          <td className="w-[120px] whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            {detail.ahtAfterCoaching}
                          </td>
                          <td className="invisible w-12 whitespace-nowrap px-3 py-3 text-center text-sm text-gray-900">
                            &nbsp;
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            },
          }}
          columns={[
            {
              name: "Agent",
              key: "agentName",
              headerClassName: "text-center w-44",
              sortable: true,
              //   filter: {
              //     previewTemplate: <PreviewFilterNames />,
              //     component: <FilterNames />,
              //   },
              render: (text, record) => {
                return (
                  <div className="inline-flex items-center space-x-2">
                    <div className="mx-auto flex h-[54px] w-[54px] items-center justify-center rounded-full bg-[#FFCB9E] p-[2px]">
                      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white p-[5px]">
                        <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-white">
                          <Avatar
                            text={text || ""}
                            // alt={record.userName}
                            backgrounds={[
                              "#f28482",
                              "#84a59d",
                              "#b08968",
                              "#eb5e28",
                              "#b8c0ff",
                            ]}
                            src=""
                            shape="circle"
                            className="absolute object-cover text-xs !font-normal text-white"
                            textColor={"#fff"}
                            textProcessor={(text) =>
                              text
                                .match(/(^\S\S?|\b\S)?/g)
                                .join("")
                                .match(/(^\S|\S$)?/g)
                                .join("")
                                .toUpperCase()
                            }
                            // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="inline-flex text-sm text-[#33475B]">
                        {text || ""}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Sessions",
              headerClassName: "text-center w-20",
              key: "coachingSessionCount",
              //   filter: {
              //     previewTemplate: <PreviewFilterTeams />,
              //     component: <FilterTeams />,
              //   },
              render: (value) => {
                return <div className="flex justify-center">{value || 0}</div>;
              },
            },
            {
              name: "Team",
              key: "agentTeam",
              sortable: true,
              //   filter: {
              //     // overview: <OverviewFilterRole />,
              //     component: <FilterRole />,
              //   },
              headerClassName: "text-center",
              className: "text-center",
            },
            {
              name: "Reason For Contact",
              key: "sessionsDetail[].reasonForContact",
              headerClassName: "text-center",
              // sortable: true,
              //   filter: {
              //     // overview: <OverviewFilterStatus />,
              //     component: <FilterStatus />,
              //   },
              render: (value, record) => (
                <div className="flex justify-center">
                  {record.sessionsDetail && record.sessionsDetail.length > 0
                    ? record.sessionsDetail[0].reasonForContact || ""
                    : ""}
                </div>
              ),
            },
            {
              name: "Coach Date",
              key: "sessionsDetail[].coachingSessionDate",
              headerClassName: "text-center",
              render: (value, record) => (
                <div className="flex justify-center">
                  {record.sessionsDetail &&
                  record.sessionsDetail.length > 0 &&
                  record.sessionsDetail[0].coachingSessionDate.length > 0
                    ? dayjs(
                        record.sessionsDetail[0].coachingSessionDate
                      ).format("MM-DD-YYYY") || ""
                    : ""}
                </div>
              ),
              // sortable: true,
              //   filter: {
              //     // overview: <OverviewFilterStatus />,
              //     component: <FilterStatus />,
              //   },
            },
            ...shortNames,
            {
              name: "Pre-AHT",
              key: "sessionsDetail[].ahtBeforeCoaching",
              headerClassName: "text-center w-[120px]",
              sortable: true,
              //   filter: {
              //     // overview: <OverviewFilterStatus />,
              //     component: <FilterStatus />,
              //   },
              render: (value, record) => (
                <div className="flex justify-center">
                  {record.sessionsDetail && record.sessionsDetail.length > 0
                    ? Number(record.sessionsDetail[0].ahtBeforeCoaching || "")
                    : ""}
                </div>
              ),
            },
            {
              name: "Post-AHT",
              key: "sessionsDetail[].ahtAfterCoaching",
              headerClassName: "text-center w-[120px]",
              sortable: true,
              //   filter: {
              //     // overview: <OverviewFilterStatus />,
              //     component: <FilterStatus />,
              //   },
              render: (value, record) => (
                <div className="flex justify-center">
                  {record.sessionsDetail && record.sessionsDetail.length > 0
                    ? Number(record.sessionsDetail[0].ahtAfterCoaching || "")
                    : ""}
                </div>
              ),
            },
          ]}
          loading={loading || loadingShortNames}
          dataSet={dataSet}
          totalItems={totalElements}
        />
      </div>
    </div>
  );
};

export default Agent;
