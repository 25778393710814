import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import ChartCard from "./ChartCard";
import Highcharts from "highcharts/highstock";
import jibeServices from "../../../services/jibe.services";
import servicesServices from "../../../services/services.services";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import { isEmpty } from "../../../helpers/util";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const colors = ["#F70", "#935AD8", "#49C2CB", "#F2547D", "#b8c0ff"];

const Performance = ({ range }) => {
  const [categories, setCategories] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const fetchSurveyQuestions = async () => {
    setLoading1(true);
    try {
      const { data } = await servicesServices.fetchSurveyQuestions({});
      setCategories(
        data.result.map((c, index) => ({
          name: c.internalName,
          title: c.shortName,
          id: c.shortName,
          color: colors[index],
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading2(true);
      const { data } = await jibeServices.fetchJibeProgressOverTimeStats({
        fromDate: `${range.from.month}/${range.from.day}/${range.from.year}`,
        toDate: `${range.to.month}/${range.to.day}/${range.to.year}`,
      });
      setDataSet(data.result);
    } catch (error) {
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (!loading1 && categories.length > 0) {
      if (!isEmpty(range.to) && !isEmpty(range.from)) fetchData();
    }
  }, [loading1]);

  useEffect(() => {
    fetchSurveyQuestions();
  }, []);

  useEffect(() => {
    fetchSurveyQuestions();
  }, [range]);

  return (
    <div className="box-wrapper">
      <div className="box-header">
        <div className="inline-flex items-center gap-4">
          <div className="inline-flex items-center gap-2">
            <h1 className="box-title">Jibe Performance</h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M8.2 5.4H6.8V4H8.2M8.2 11H6.8V6.8H8.2M7.5 0.5C6.58075 0.5 5.67049 0.68106 4.82122 1.03284C3.97194 1.38463 3.20026 1.90024 2.55025 2.55025C1.2375 3.86301 0.5 5.64348 0.5 7.5C0.5 9.35651 1.2375 11.137 2.55025 12.4497C3.20026 13.0998 3.97194 13.6154 4.82122 13.9672C5.67049 14.3189 6.58075 14.5 7.5 14.5C9.35651 14.5 11.137 13.7625 12.4497 12.4497C13.7625 11.137 14.5 9.35651 14.5 7.5C14.5 6.58075 14.3189 5.67049 13.9672 4.82122C13.6154 3.97194 13.0998 3.20026 12.4497 2.55025C11.7997 1.90024 11.0281 1.38463 10.1788 1.03284C9.3295 0.68106 8.41925 0.5 7.5 0.5Z"
                fill="#7B93AF"
              />
            </svg>
          </div>
        </div>

        <NavLink
          to="/dashboard/jibe"
          className="btn btn-secondary btn-pill !gap-6 !p-3 !px-5"
          // onClick={toggleDrawer}
        >
          <span className="cursor-pointer">Jibe Dashboard</span>
        </NavLink>
      </div>

      <div className="w-full border-t border-[#C0EAED] pt-4">
        <div className="grid grid-cols-2 items-center gap-6 lg:grid-cols-3 xl:gap-8 xl:grid-cols-4">
          {loading1 ? (
            <>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
              <div className="relative w-full rounded-lg border border-transparent p-6">
                <Skeleton height={180} />
              </div>
            </>
          ) : (
            <>
              {categories
                .sort((a, b) => a.title - b.title)
                .map((category, index) => (
                  <ChartCard
                    key={`per-${index}`}
                    range={
                      !isEmpty(range.to) && !isEmpty(range.from)
                        ? dayjs(
                            `${range.to.year}-${range.to.month}-${range.to.day}`
                          ).diff(
                            dayjs(
                              `${range.from.year}-${range.from.month}-${range.from.day}`
                            ),
                            "day"
                          )
                        : 1
                    }
                    chartName={category.title}
                    data={[
                      ...[...(dataSet || [])].map((x) => ({
                        x: dayjs(x.effectiveDate, "M/D/YYYY").valueOf(),
                        y: x[category.name],
                      })),
                    ]}
                    color={category.color}
                    colorGradient={{
                      linearGradient: { x1: 1, y1: 0, x2: 0, y2: 0 },
                      stops: [
                        [
                          0,
                          Highcharts.color(category.color)
                            .setOpacity(0.9)
                            .get("rgb"),
                        ],
                        [
                          0.1,
                          Highcharts.color(category.color)
                            .setOpacity(0.8)
                            .get("rgb"),
                        ],
                        [
                          0.2,
                          Highcharts.color(category.color)
                            .setOpacity(0.7)
                            .get("rgb"),
                        ],
                        [
                          0.3,
                          Highcharts.color(category.color)
                            .setOpacity(0.6)
                            .get("rgb"),
                        ],
                        [
                          0.4,
                          Highcharts.color(category.color)
                            .setOpacity(0.5)
                            .get("rgb"),
                        ],
                        [
                          0.5,
                          Highcharts.color(category.color)
                            .setOpacity(0.4)
                            .get("rgb"),
                        ],
                        [
                          0.6,
                          Highcharts.color(category.color)
                            .setOpacity(0.3)
                            .get("rgb"),
                        ],
                        [
                          0.7,
                          Highcharts.color(category.color)
                            .setOpacity(0.2)
                            .get("rgb"),
                        ],
                        [
                          0.8,
                          Highcharts.color(category.color)
                            .setOpacity(0.1)
                            .get("rgb"),
                        ],
                        [
                          0.9,
                          Highcharts.color(category.color)
                            .setOpacity(0)
                            .get("rgb"),
                        ],
                        [
                          1,
                          Highcharts.color("#ffffff").setOpacity(9).get("rgba"),
                        ],
                      ],
                    }}
                  />
                ))}
              <ChartCard
                range={
                  !isEmpty(range.to) && !isEmpty(range.from)
                    ? dayjs(
                        `${range.to.year}-${range.to.month}-${range.to.day}`
                      ).diff(
                        dayjs(
                          `${range.from.year}-${range.from.month}-${range.from.day}`
                        ),
                        "day"
                      )
                    : 1
                }
                chartName={"Jibe Sessions"}
                data={[
                  ...[...(dataSet || [])].map((x) => ({
                    x: dayjs(x.effectiveDate, "M/D/YYYY").valueOf(),
                    y: Math.round(
                      (x.jibeSessionsPast30DaysCount / x.agentCount) * 100
                    ),
                  })),
                ]}
                color={colors[categories.length]}
                colorGradient={{
                  linearGradient: { x1: 1, y1: 0, x2: 0, y2: 0 },
                  stops: [
                    [
                      0,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.9)
                        .get("rgb"),
                    ],
                    [
                      0.1,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.8)
                        .get("rgb"),
                    ],
                    [
                      0.2,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.7)
                        .get("rgb"),
                    ],
                    [
                      0.3,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.6)
                        .get("rgb"),
                    ],
                    [
                      0.4,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.5)
                        .get("rgb"),
                    ],
                    [
                      0.5,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.4)
                        .get("rgb"),
                    ],
                    [
                      0.6,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.3)
                        .get("rgb"),
                    ],
                    [
                      0.7,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.2)
                        .get("rgb"),
                    ],
                    [
                      0.8,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0.1)
                        .get("rgb"),
                    ],
                    [
                      0.9,
                      Highcharts.color(colors[categories.length])
                        .setOpacity(0)
                        .get("rgb"),
                    ],
                    [1, Highcharts.color("#ffffff").setOpacity(9).get("rgba")],
                  ],
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Performance;
