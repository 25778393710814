import "react-modern-calendar-datepicker/lib/DatePicker.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "rc-segmented/assets/index.css";
import "swiper/css";
import "../styles/index.css";

import { Link, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import AuthenticationLayout from "../components/shared/layouts/AuthenticationLayout";
import PrivateLayout from "../components/shared/layouts/PrivateLayout";
import { Suspense } from "react";
import authRoutes from "./authRoutes";
import privateRoutes from "./privateRoutes";

function App() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <AuthenticationLayout />,
      children: [...authRoutes],
    },
    {
      path: "/dashboard",
      element: <PrivateLayout />,
      children: [...privateRoutes],
      handle: {
        crumb: () => <Link to="/dashboard/overview">Dashboard</Link>,
      },
    },
  ]);

  return (
    <>
      <Suspense fallback={"Loading"}>
        <Toaster />
        <div className="min-h-screen">
          <RouterProvider router={routes} />
        </div>
      </Suspense>
    </>
  );
}

export default App;
