import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import coachingServices from "../../services/coaching.services";

const initialState = {
  loading: false,
  error: undefined,
  originalDataSet: [],
  dataSet: [],
  totalElements: 0,
  filters: {},
};

export const fetchAgents = createAsyncThunk(
  "coaching/fetchAgents",
  async ({ ...params }) => {
    const { data } = await coachingServices.fetchCoachedAgents(params);

    return data;
  }
);

export const coachingSlice = createSlice({
  name: "coaching",
  initialState,
  reducers: {
    setData: (state, action) => {
      state = { ...state, dataSet: action.payload };

      return state;
    },
    agentsUpdateOne: (state, action) => {
      // TODO: use url or base64 for avatar
      // const { avatar, ...payload } = action.payload;
      const { ...payload } = action.payload;
      const sourceIndex = [...(state.originalDataSet || [])].findIndex(
        (t) => t.id === payload.id
      );

      let originalDataSet = [...state.originalDataSet];
      if (sourceIndex > -1) {
        originalDataSet.splice(sourceIndex, 1, payload);
      }

      const index = [...(state.dataSet || [])].findIndex(
        (t) => t.id === payload.id
      );

      let dataSet = [...state.dataSet];
      if (index > -1) dataSet.splice(index, 1, payload);

      state = { ...state, originalDataSet, dataSet };

      return state;
    },
    agentsDeleteOne: (state, action) => {
      state.dataSet = [...state.dataSet].filter((t) => t.id !== action.payload);
      state.originalDataSet = [...state.originalDataSet].filter(
        (t) => t.id !== action.payload
      );

      return state;
    },
    agentsCreate: (state, action) => {
      state.originalDataSet = [...state.originalDataSet, action.payload];
      state.dataSet = [...state.dataSet, action.payload];

      return state;
    },
    agentFilter: (state, action) => {
      let filters = { ...state.filters };
      if (action.payload.action === "ADD") {
        filters = { ...filters, [action.payload.key]: action.payload.value };
      } else if (action.payload.action === "REMOVE") {
        delete filters[action.payload.key];
      }
      state = {
        ...state,
        filters,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAgents.pending, (state) => {
      state = { ...state, loading: true, error: undefined, totalElements: 0 };
      return state;
    });
    builder.addCase(fetchAgents.fulfilled, (state, action) => {
      const dataSet = [...action.payload.result].map((user) => {
        return {
          ...user,
        };
      });

      state = {
        ...state,
        loading: false,
        originalDataSet: dataSet,
        dataSet: dataSet,
        totalElements:
          // action.payload.user.totalRows ||
          dataSet.length || 0,
      };
      return state;
    });
    builder.addCase(fetchAgents.rejected, (state, action) => {
      state = { ...state, loading: false, error: action.payload };
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  agentsUpdateOne,
  agentsDeleteOne,
  agentsCreate,
  agentFilter,
  setData,
} = coachingSlice.actions;

export default coachingSlice.reducer;
