import React from "react"

const Channel = ({ data = [] }) => {
  return (
    <div>
      <div className="mt-20 px-4">
        <div className="flex justify-between">
          <span className="text-[#425B76]">List of Channel</span>
          <span className="text-[#949494]">{data.length} Channel</span>
        </div>
        <div className="mt-6 flex flex-col">
          {data.map((item, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div
                className="relative inline-flex h-12 min-w-[5%] space-x-2 rounded-[4px_8px_8px_4px] p-2 first:text-white"
                style={{
                  background: item.color || "#64C18F",
                  width: item.percentage + "%"
                }}
              >
                <span className="absolute left-4 top-1/2 -translate-y-1/2 capitalize text-white">
                  {item.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <a
        href="/"
        className="mt-8 flex w-full items-center justify-center rounded-full bg-[#E6F7F8] py-3"
      >
        <span className="text-[#7B93AF]">Show survay Report</span>
      </a>
    </div>
  )
}

export default Channel
