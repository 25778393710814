import React, { useEffect, useState } from "react";

import CircleChart from "./CircleChart";

export default function ChartCard(props) {
  const { range = 1, chartName, data, color, colorGradient } = props;

  const [upDownPercentAmount, setUpDownPercentAmount] = useState(0);
  const [name, setName] = useState("");

  useEffect(() => {
    if (chartName) {
      setName(chartName);
    }

    if (data && data.length) {
      setUpDownPercentAmount(data[data.length - 1].y - data[0].y);
    }
  }, [props]);

  return (
    <div className={`flex justify-center items-center rounded-lg p-6 px-0 gap-4`}>
      <div className="inline-flex flex-col items-center justify-center whitespace-nowrap">
        <span>{name}</span>
        <div className="flex items-center gap-2 text-sm">
          <span style={{ padding: "0px" }}>
            {/* {upDownPercentAmount > 0 ? (
              <svg
                height={"1rem"}
                width={"1rem"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 25 25"
                strokeWidth="4"
                stroke="#11b4aa"
                style={{ padding: "0px" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                />
              </svg>
            ) : (
              upDownPercentAmount < 0 && ( */}
                <svg
                  height={"1rem"}
                  width={"1rem"}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 25 25"
                  strokeWidth="4"
                  stroke="#fe765c"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
                  />
                </svg>
              {/* )
            )} */}
          </span>
          <span
            style={{
              color: "#98abc2",
              margin: "0 0 0 2px",
            }}
          >
            {/* {upDownPercentAmount}% */}
            5%
          </span>
          <span
            style={{
              backgroundColor: "#f2f6f9",
              borderRadius: "5px",
              color: "#98abc2",
              padding: "2px 5px",
              margin: "0px",
            }}
          >
            {range <= 1 ? "24 h" : `${range} d`}
          </span>
        </div>
      </div>

      <div className="inline-block w-full max-w-[100px]">
        <CircleChart
          data={data}
          color={color}
          name={chartName}
          colorGradient={colorGradient}
        />
      </div>
    </div>
  );
}
