import PageItem from "./PageItem";
import React from "react";
import paginate from "./paginate";

// interface IProps {
//   totalItems: number;
//   currentPage: number;
//   pageSize: number;
//   maxPages: number;
//   setPage: (page: number) => void;
// }

const Pagination = (props) => {
  const {
    totalItems: totalitems,
    currentPage: currentpage,
    pageSize: pagesize,
    maxPages: maxpages,
    setPage,
  } = props;

  // eslint-disable-next-line
  const {
    // eslint-disable-next-line
    totalItems,
    // eslint-disable-next-line
    currentPage,
    // eslint-disable-next-line
    pageSize,
    // eslint-disable-next-line
    totalPages,
    // eslint-disable-next-line
    startPage,
    // eslint-disable-next-line
    endPage,
    // eslint-disable-next-line
    startIndex,
    // eslint-disable-next-line
    endIndex,
    pages,
  } = paginate(totalitems, currentpage, pagesize, maxpages);

  return (
    <div className="flex w-full items-center gap-2">
      {/* <PageItem
        currentPage={currentpage}
        value={1}
        setPage={setPage}
        shadow={false}
        label={
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              />
            </svg>
          </div>
        }
        disabled={currentpage === 1}
      /> */}

      <button
        type="button"
        onClick={() => setPage(currentpage - 1)}
        disabled={currentpage === 1}
        className={`inline-flex cursor-pointer select-none justify-center rounded-md pr-2 text-[#425B76] text-sm font-normal`}
      >
        <div className="flex items-center gap-1">
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 1.5L1 5L4.5 8.5"
              stroke="#425B76"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Previous</span>
        </div>
      </button>

      {pages && pages.length && Number(pages[pages.length - 1]) > maxpages ? (
        <span className="inline-flex select-none items-center justify-center">
          ...
        </span>
      ) : (
        ""
      )}

      {pages.map((key) => (
        <PageItem
          key={key}
          currentPage={currentpage}
          setPage={setPage}
          shadow={false}
          value={key}
          label={key}
        />
      ))}

      {pages &&
      pages.length &&
      currentpage < totalPages - 1 &&
      Number(pages[pages.length - 1]) !== totalPages ? (
        <span className="inline-flex select-none items-center justify-center">
          ...
        </span>
      ) : (
        ""
      )}

      <button
        type="button"
        onClick={() => setPage(currentpage + 1)}
        disabled={currentpage === Math.ceil(totalItems / pagesize)}
        className={`inline-flex cursor-pointer select-none justify-center rounded-md pl-2 text-[#425B76] text-sm font-normal`}
      >
        <div className="flex items-center gap-1">
          <span>Next</span>
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 8.5L5 5L1.5 1.5"
              stroke="#425B76"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>

      {/* <PageItem
        currentPage={currentpage}
        value={Math.ceil(totalItems / pagesize)}
        setPage={setPage}
        shadow={false}
        label={
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
          </div>
        }
        disabled={currentpage === Math.ceil(totalItems / pagesize)}
      /> */}
    </div>
  );
};

export default Pagination;
