import React, { useRef } from "react"

import Channel from "./Channel"
import RFC from "./RFC"
import Responses from "./Responses"
import Tabs from "rc-tabs"

const OverallPerformanceTeamBreakdown = () => {
  const contentRef = useRef(null)

  return (
    <>
      <Tabs
        ref={contentRef}
        prefixCls="phil-tabs"
        defaultActiveKey="channel"
        className="!border-0"
        items={[
          {
            key: "rfc",
            label: (
              <div className="flex items-center space-x-2">
                <span>RFC</span>
              </div>
            ),
            children: <RFC />
          },
          {
            key: "channel",
            label: (
              <div className="flex items-center space-x-2">
                <span>Channel</span>
              </div>
            ),
            children: (
              <Channel
                data={[
                  { name: "Phone", percentage: 90, color: "#FFB06B" },
                  { name: "Email", percentage: 80, color: "#FFCB9E" },
                  { name: "Social", percentage: 70, color: "#70CFD6" },
                  { name: "Category", percentage: 60, color: "#98DDE1" },
                  { name: "Unknown Unknown", percentage: 50, color: "#AE83E2" },
                  { name: "Unknown", percentage: 40, color: "#C9ADEB" },
                  { name: "Unknown", percentage: 30, color: "#0091AE" },
                  {
                    name: "Unknown",
                    percentage: 10,
                    color: "rgba(0, 145, 174, 0.8)"
                  }
                ]}
              />
            )
          },
          {
            key: "responses",
            label: (
              <div className="relative flex items-center space-x-2">
                <span>Responses</span>
              </div>
            ),

            children: (
              <Responses
                data={{
                  ASAT: [
                    {
                      name: "Fair",
                      y: 7
                    },
                    {
                      name: "Good",
                      y: 28
                    },
                    {
                      name: "Poor",
                      y: 65
                    }
                  ],
                  RES: [
                    {
                      name: "Satisfied",
                      y: 7
                    },
                    {
                      name: "Unsure",
                      y: 28
                    },
                    {
                      name: "Dissatisfied",
                      y: 65
                    }
                  ],
                  CSAT: [
                    {
                      name: "Yes",
                      y: 7
                    },
                    {
                      name: "No",
                      y: 3
                    }
                  ]
                }}
              />
            )
          }
        ]}
      />
    </>
  )
}

export default OverallPerformanceTeamBreakdown
